import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Colors from "../../utils/Colors";
import product from "../../../assets/img/vanguardBottom.png";
import VgButton from "../general/VgButton";
export const Packages = ({ theme }) => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "50686187854";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <>
      <Container maxWidth={"md"} sx={{ mt: 6 }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item md={12} container justifyContent={"center"} mb={6} mt={4}>
            <Typography variant="h4" sx={{ fontWeight: "800", fontSize: 28 }}>
              Escoge tu paquete
            </Typography>
          </Grid>
          <Grid item md={6} container justifyContent={"center"} my={2}>
            <Box
              sx={{
                backgroundColor: "#5d7280",
                width: 350,
                height: 500,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "18px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f0fcff",
                  width: 300,
                  height: 450,
                  display: "flex",
                  flexDirection: "column",

                  textAlign: "center",
                  borderRadius: "18px",
                  position: "relative",
                }}
              >
                <Box mt={3}>
                  <Typography variant="'body1" sx={{ color: "#004e8b" }}>
                    Vanguard
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ fontSize: 16, fontWeight: "800", color: "#004e8b" }}
                  >
                    ASSOCIATED PACKAGE
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: 28,
                      fontWeight: "800",
                      display: "flex",
                      textAlign: "center",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      color: "#004e8b",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 16,
                        marginRight: 4,
                        marginTop: 4,
                        color: "#004e8b",
                      }}
                    >
                      $
                    </span>
                    199
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 12, color: "#004e8b" }}
                  >
                    $50 MENSUALIDAD
                  </Typography>
                </Box>
                <Box
                  sx={{
                    minHeight: 250,
                    height: 250,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 12, color: "black" }}
                  >
                    App - Sinthetic Power Lab
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 12, color: "black" }}
                  >
                    Vanguard Pro Academy
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 12, color: "black" }}
                  >
                    V-Signal
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 12, color: "black" }}
                  >
                    -Coaching-Financiero
                  </Typography>
                </Box>
                <Box>
                  <Button
                    sx={{
                      backgroundColor: Colors.light,
                      border: "solid 2px",
                      borderColor: Colors.button,
                      color: Colors.dark,
                      px: 2.5,
                      fontWeight: "bold",
                    }}
                    onClick={handleWhatsAppClick}
                  >
                    Información
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} container justifyContent={"center"} my={2}>
            <Box
              sx={{
                backgroundColor: "#5d7280",
                width: 350,
                height: 500,

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "18px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(0,31,87,1) 34%, rgba(0,66,185,1) 100%)", // Gradient background
                  width: 300,
                  height: 450,
                  display: "flex",
                  flexDirection: "column",

                  textAlign: "center",
                  borderRadius: "18px",
                  position: "relative",
                }}
              >
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="'body1">Vanguard</Typography>
                  <Typography
                    variant="h3"
                    sx={{ fontSize: 16, fontWeight: "800" }}
                  >
                    LUX MEMBER
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: 28,
                      fontWeight: "800",
                      display: "flex",
                      textAlign: "center",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <span
                      style={{ fontSize: 16, marginRight: 4, marginTop: 4 }}
                    >
                      $
                    </span>
                    499
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 12 }}>
                    $50 MENSUALIDAD
                  </Typography>
                </Box>
                <Box
                  sx={{
                    minHeight: 250,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="body1" sx={{ fontSize: 12 }}>
                    -App
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 12 }}>
                    -Sinthetic Power Lab Avanzado
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 12 }}>
                    -Vanguard pro Academy
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 12 }}>
                    -Expert Advisor
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 12 }}>
                    -V Signal
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 12 }}>
                    -Coaching-Financiero
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: 12 }}>
                    Premium Assets
                  </Typography>
                  {/* <Typography variant="body1" sx={{ fontSize: 12 }}>
                    -Institucional Strategy 2-5%
                  </Typography> */}
                  {/* <Typography variant="body1" sx={{ fontSize: 12 }}>
                    -IA Investment Strategy 6-8% 45 días
                  </Typography> */}
                </Box>
                <Box>
                  <Button
                    sx={{
                      backgroundColor: Colors.light,
                      border: "solid 2px",
                      borderColor: Colors.light,
                      color: Colors.vibrantBlue,
                      px: 2.5,
                      fontWeight: "bold",
                    }}
                    onClick={handleWhatsAppClick}
                  >
                    Información
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 10,
          mt: 8,
          [theme.breakpoints.down("md")]: {
            px: 2,
          },
        }}
      >
        <Grid container columnGap={1} maxWidth={"lg"}>
          <Grid item md={7} container justifyContent={"center"}>
            <Box
              component={"img"}
              src={product}
              sx={{
                height: "450px",

                [theme.breakpoints.down("md")]: {
                  width: "90%",
                  height: "auto",
                  mb: 1,
                },
              }}
            />
          </Grid>
          <Grid item md={4} container rowGap={1}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "800",
                fontSize: 28,
                [theme.breakpoints.down("md")]: {
                  my: 2,
                },
              }}
            >
              ¿Buscas financiamiento para tu cuenta de trading?
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              Vanguard te ofrece opciones exclusivas. Te proporcionamos tres
              caminos:
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              Primero, puedes optar por que nosotros pasemos tus cuentas de
              fondeo. Nuestros expertos te guiarán a través de cada paso del
              proceso.
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              Vanguard te ofrece opciones exclusivas. Te Segundo, si prefieres
              que operemos tus cuentas por ti, estás en buenas manos. Nuestros
              profesionales altamente calificados se encargarán de tus
              inversiones.
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              Tercero, te ofrecemos educación. Te capacitamos para que puedas
              tomar el control de tus inversiones. Te proporcionamos las
              herramientas y el conocimiento necesarios para tener éxito.
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 12 }}>
              Además, si buscas una experiencia aún más exclusiva, puedes unirte
              a nuestra comunidad privada Osprey. Opera en vivo en las sesiones
              de Nueva York y Londres junto a nuestros mejores educadores.
              También tendrás acceso a nuestro canal privado de señales.
            </Typography>
            <Box>
              <VgButton
                title={"Información"}
                bgColor={Colors.yellowAcademy}
                bgColorB={Colors.yellowDark}
                height={50}
                fontSize={18}
                width={175}
                onPress={handleWhatsAppClick}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
