import React from "react";
import woman from "../../../assets/img/seccion-04.png";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { yellow } from "@mui/material/colors";
import Colors from "../../utils/Colors";
import { aboutCardData } from "../../utils/homeData";
export const About = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      mt={10}
      columnGap={6}
      sx={{
        position: "relative",
        [theme.breakpoints.down("md")]: {
          px: 2,
        },
      }}
    >
      <Grid
        item
        md={4}
        order={{ xs: 2, sm: 2, md: 1 }}
        container
        justifyContent={"flex-start"}
      >
        <Box
          component={"img"}
          src={woman}
          sx={{
            height: "65vh",
            marginLeft: "auto",
            [theme.breakpoints.down("md")]: {
              height: "85vh",
              marginLeft: 0,
            },
          }}
        />
      </Grid>
      <Grid item md={5} order={{ xs: 1, sm: 1, md: 2 }} mb={10}>
        <Typography variant="h4" sx={{ fontSize: 42, fontWeight: "800" }}>
          ¿Qué es
        </Typography>
        <Typography variant="h4" sx={{ fontSize: 42, fontWeight: "800" }}>
          Vanguard?
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: 15, fontWeight: "600" }}
          my={2}
        >
          Vanguard es una comunidad dedicada a la educación y el éxito en los
          mercados financieros, se destaca por su enfoque en la transparencia y
          el crecimiento de todos sus miembros.
        </Typography>
        <Typography variant="body1" sx={{ fontSize: 15, fontWeight: "600" }}>
          Posee un ecosistema digital que permite a sus socios aprender y
          capitalizarse , a través de la combinación de educación, asesoramiento
          de expertos y el uso de sistemas automatizados de alta tecnología . Su
          revolucionario portafolio de oportunidades contemplan una verdadera
          vanguardia para la comunidad, en una sola plataforma
        </Typography>
      </Grid>
      <Grid
        item
        container
        md={10}
        sm={8}
        sx={{
          background:
            "linear-gradient(90deg, rgba(237,198,4,1) 14%, rgba(149,110,0,1) 64%);",
          height: 200,
          [theme.breakpoints.down("md")]: {
            height: 350,
            // width: "50%",
          },
          [theme.breakpoints.down("sm")]: {
            height: 500,
            width: "80%",
          },
          width: "100%",
          borderRadius: "18px",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          bottom: -100,

          //   marginBottom: 50,
        }}
      >
        {aboutCardData.map((item) => {
          return (
            <Grid
              item
              container
              justifyContent={"center"}
              alignItems={"center"}
              md={3}
              sm={6}
              key={item.id}
            >
              {item.icon}
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "800", fontSize: 16, ml: 0.5 }}
                >
                  {item.text}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "800", fontSize: 16, ml: 0.5 }}
                >
                  {item.subtitle}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
