import { Box, Container, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import SideDrawer from "./SideDrawer";
import { VanguardNavbar } from "./shared/VanguardNavbar";
import { styles } from "../../assets/styles/styles";

const SidebarLayout = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [firstUse, setFirstUse] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    // console.log('opened ==', open);
  };
  const contentStyle = {
    marginLeft: open ? "0px" : "calc(64px + 1px)",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      ...(!open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    },
  };
  return (
    <>
      {/* <VanguardNavbar /> */}
      <Box sx={styles.headerBackground}></Box>

      <Box sx={{ mt: 2, mb: 4 }}>
        <Outlet />
      </Box>
    </>
  );
};

export default SidebarLayout;
