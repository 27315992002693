import { SET_BINARIES, SET_IS_LOADING, SET_SIGNALS } from "../actions/signals";
import { types } from "../types/types";

const initialState = {
  signals: [],
  isLoading: false,
};

export const signalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIGNALS:
      return {
        ...state,
        signals: action.payload.signals,
        // displayName: action.payload.displayName,
      };
    case SET_BINARIES:
      return {
        ...state,
        binaries: action.payload.binaries,
        // displayName: action.payload.displayName,
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        // displayName: action.payload.displayName,
      };

    default:
      return state;
  }
};
