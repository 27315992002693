import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducer/authReducer";

import { itemsReducer } from "./reducer/itemsReducer";
import { usersReducer } from './reducer/usersReducer';
import { signalsReducer } from "./reducer/signalsReducer";
import { schedulesReducer } from "./reducer/schedulesReducer";
import { notificationsReducer } from "./reducer/notificationsReducer";



export const store = configureStore(
  {
    reducer: {
      items: itemsReducer,
      auth: authReducer,
      signals: signalsReducer,
      users:usersReducer,
      schedules:schedulesReducer,
      notifications:notificationsReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
