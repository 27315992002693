import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
  apiKey: "AIzaSyDHPbbO6Npzt1srt--4nsStoFp955HNrww",
  authDomain: "vanguard-e2803.firebaseapp.com",
  projectId: "vanguard-e2803",
  storageBucket: "vanguard-e2803.appspot.com",
  messagingSenderId: "263261478025",
  appId: "1:263261478025:web:4698b16aa47d4f241f7a58",
  measurementId: "G-2V5S908GDH",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();

export { storage, db, auth, analytics };
