export const types = {
  // Login
  login: "[Auth] Login",
  logout: "[Auth] Logout",
  isLoadingAuth: "[Auth] isLoading",
  errorMsg: "[Auth] error",

  // User Info
  getUserInfoFromDb: "[User] GetUserInfoFromDb",
  registerError: "[User] RegisterError",
  isLoadingUser: "[User] IsLoadingUser",
  logoutUser: "[User] Logout",
  getActiveOrder: "[User] ActiveOrder",
  getOrderHistory: "[User] OrderHistory",

  //Items
  getItems: "[Items] GetItems",
  changeCategory: "[Items] ChangeCategory",
  searchValue: "[Items] ChangeSearch",
  isLoadingItems: "[Items] IsLoadingItems",

  //Drivers
  getDrivers: "[Drivers] GetDrivers",
  isLoadingDrivers: "[Drivers] IsLoadingDrivers",

  //Users
  getUsers: "[Users] GetUsers",
  isLoadingUsers: "[Users] IsLoadingUsers",
};
