import React from "react";
import { CircularProgress, Button, Typography, Grid } from "@mui/material";
import LogoImage from "../../../assets/img/V2.png";

import Colors from "../../utils/Colors";
import { styles } from "../../../assets/styles/styles";

const VgButton = ({
  title,
  onPress,
  loading,
  bgColor,
  bgColorB,
  color,
  width,
  fontSize,
  height,
  logo,
  fwLight,
  shadow,
  disabled,
  borderRadius,
}) => {
  const defaultBgColor = Colors.grey2;
  const buttonStyle = {
    ...styles.button,
    background: `linear-gradient(to right, ${
      bgColor ? bgColor : styles.button.backgroundColor
    }, ${bgColorB ? bgColorB : defaultBgColor})`,
    width: width ? width : styles.button.width,
    height: height ? height : styles.button.height,
    boxShadow: shadow ? "rgba(0, 0, 0, 0.35) 0px 5px 10px;" : null,
    borderRadius: borderRadius ? borderRadius : "18px",
  };

  return (
    <Button sx={buttonStyle} onClick={onPress} fullWidth disabled={disabled}>
      {loading ? (
        <Grid container alignItems="center">
          <Grid item>
            <CircularProgress size={24} color="secondary" />
          </Grid>
          <Grid item>
            {logo && (
              <img
                src={LogoImage}
                alt="Logo"
                style={{
                  width: 25,
                  height: 25,
                  marginRight: 10,
                }}
              />
            )}
            <Typography
              variant="body2"
              sx={{
                color: color,
                fontSize: fontSize,
                marginLeft: logo ? 5 : 0,
                fontFamily: "Montserrat",
                fontWeight: fwLight ? "500" : "700",
              }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography
          variant="body2"
          sx={{
            color: color,
            fontSize: fontSize,
            fontFamily: "Montserrat",
            fontWeight: fwLight ? "500" : "700",
          }}
        >
          {title}
        </Typography>
      )}
    </Button>
  );
};

export default VgButton;
