import {
  MdSignalCellular1Bar,
  MdPhoto,
  MdCastForEducation,
  MdPriorityHigh,
  MdHome,
  MdSupervisedUserCircle,
  MdEditCalendar,
  MdPeople,
} from "react-icons/md";

export const menu = [
  // {
  //   id: "1",
  //   route: "/home",
  //   title: "Inicio",
  //   icon: <MdHome />,
  // },
  {
    id: "1",

    route: "/items",
    title: "Contenido",
    icon: <MdHome />,
  },
  {
    id: "2",

    route: "/services",
    title: "Videos",
    icon: <MdCastForEducation />,
  },
];
export const menuAcademy = [
  // {
  //   id: "1",
  //   route: "/home",
  //   title: "Inicio",
  //   icon: <MdHome />,
  // },

  {
    id: "1",
    route: "/academy",
    title: "Banners Academia",
    icon: <MdCastForEducation />,
  },

  {
    id: "2",
    title: "Comunidad",
    route: "/community",
    icon: <MdPeople />,
  },
  {
    id: "3",
    title: "Trading",
    icon: <MdHome />,
    submenu: [
      {
        route: "/forex",
        title: "Forex",
        icon: <MdCastForEducation />,
      },
      {
        route: "/synthetics",
        title: "Sintéticos",
        icon: <MdCastForEducation />,
      },
      {
        route: "/cryptos",
        title: "Cryptos",
        icon: <MdCastForEducation />,
      },
    ],
  },
];

export const menuSignals = [
  {
    id: "8",
    route: "/signals",
    title: "Señales",
    icon: <MdSignalCellular1Bar />,
  },
];
export const menuSchedules = [
  {
    id: "6",
    route: "/schedules/masterclass",
    title: "Masterclass",
    icon: <MdEditCalendar />,
  },
  {
    id: "6",
    route: "/schedules/sessions",
    title: "Sessions",
    icon: <MdEditCalendar />,
  },
];
export const menuUsers = [
  {
    id: "7",
    route: "/users",
    title: "Control de Usuarios",
    icon: <MdSupervisedUserCircle />,
  },
];
export const menuNotifications = [
  {
    id: "5",
    title: "Notificaciones",
    icon: <MdPriorityHigh />,
    submenu: [
      {
        route: "/notifications",
        title: " Push",
        icon: <MdPriorityHigh />,
      },
      {
        route: "/weekly-notifications",
        title: "Semanales",
        icon: <MdPriorityHigh />,
      },
    ],
  },
];
