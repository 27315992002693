import { createTheme } from "@mui/material/styles";
import { styles } from "./styles";
const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#06C8FD",
    },
    secondary: {
      main: "#181619",
    },
    success: {
      main: "#69D482",
    },
    error: {
      main: "#FF5445",
    },
  },

  shape: {
    borderRadius: 18,
  },
  spacing: 10,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },

  typography: {
    fontFamily: [
      '"Mulish"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "-apple-system",
      "BlinkMacSystemFont",
    ].join(","),

    body1: {
      fontSize: "0.8rem !important",

      fontWeight: "600 !important",
    },
  },

  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
  //   MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  //     color: rgba(0, 0, 0, 0.87);
  // }
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          color: "white",
          // backgroundColor:'#06C8FD',
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",

          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        },

        MuiTableSortLabel: {
          root: {
            color: "red",
          },
        },
      },
    },
    MuiListItem: {
      active: {
        color: "red !important",
        backgroundColor: "blue",
      },
    },
    // MuiPickersTimePicker: {
    //   styleOverrides: {
    //     root: {
    //       color: "red !important",
    //       backgroundColor: "blue",
    //     },
    //   },
    // },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          color: "white",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: "#181619",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#181619",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: "#dda74f",
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          // backgroundColor: "transparent",
          // maxHeight: '64px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginBottom: 10,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.6rem !important",
          marginLeft: "10px",
        },
      },

      // we don't need `focused: {}` with overrides
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: `1px solid ${styles.grey[500]}`,
          color: "white",
          outline: `1px solid transparent`,
          "&$focused": {
            border: `1px solid red,`,
            outline: `1px solid red`,
          },
        },
      },

      // we don't need `focused: {}` with overrides
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            color: "white", // Set the text color to white
          },
          label: {
            color: "white", // Set the label text color to white
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        input: {
          fontSize: "0.8rem !important",
        },
        // shrink: {
        //   color: "#8D8D8D",
        // },
        // outlined: {
        //   color: "#8D8D8D",
        //   "&.Mui-focused": {
        //     color: "#8D8D8D"
        //   },
        // },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: "0.8rem !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: "0.8rem !important",
        },

        root: {
          "&.Mui-focused": {
            fontWeight: "bold",
          },
        },
        notchedOutline: {
          borderWidth: 1,
          // borderColor: "#4A90E2",
          "&.Mui-focused": {
            fontWeight: "bold",
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#181619",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          minWidth: 300,
          textAlign: "center",
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          border: "none",
          borderTopRightRadius: 1,
          borderTopLeftRadius: 1,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: "white",
        },
        "&.Mui-active": {
          color: "white !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#181619 !important",
          color: "white",

          maxWidth: 100,
        },
        root: {
          maxWidth: 100,
          textAlign: "center",
          color: "#295252",
        },
        paddingDefault: {
          padding: "40px 24px 40px 16px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          maxWidth: "50px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          boxShadow: "none !important",
        },
      },
    },

    // MUIDataTableHeadCell: {
    //   styleOverrides: {
    //     head: {
    //       maxWidth: 100,
    //       textAlign: "center",
    //     },
    //   },
    // },
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "white",
      },
    },
  },
});

export default lightTheme;
