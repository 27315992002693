import React from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import { communityCards } from "../../utils/homeData";
import Colors from "../../utils/Colors";
import { styles } from "../../../assets/styles/styles";

export const Community = () => {
  const theme = useTheme();
  const cardSize = 200; // Set the fixed size for the cards

  return (
    <Box>
      <Grid container justifyContent={"center"} my={5}>
        <Grid item md={6}>
          <Typography variant="h4" textAlign={"center"}>
            ¿Por qué unirse a nuestra comunidad?
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={{ xs: 2, md: 1 }}
        rowGap={2}
        justifyContent={"center"}
        sx={{
          [theme.breakpoints.down("md")]: {
            px: 2,
          },
        }}
      >
        {communityCards.map((item) => {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              key={item.id}
              sx={{}}
              container
              justifyContent={"center"}
            >
              <Box
                sx={{
                  backgroundColor: Colors.greyBlue,
                  width: cardSize,
                  height: cardSize,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: "8px",
                  transition: "background-color 0.3s", // Smooth transition effect
                  "&:hover": {
                    background: `linear-gradient(45deg, ${Colors.yellow} 30%, ${Colors.yellowDark} 90%)`, // Linear gradient on hover
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle1" sx={{ px: 1 }}>
                    {item.text}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
