import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Container,
  Grid,
  useMediaQuery,
  Menu,
  MenuItem,
  useTheme,
  Popover,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../../assets/img/logos/logoWhite.png";
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import VgButton from "../general/VgButton";

export const VanguardNavbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isMedium = useMediaQuery("(max-width: 900px)");
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigateTo = (link) => {
    navigate(link);
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <AppBar
      position="static"
      sx={{
        maxHeight: 200,
        background: "transparent",
        boxShadow: "none",
        mt: 2,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            backgroundColor: "transparent",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid
              item
              container
              direction={"row"}
              md={3}
              lg={2}
              xs={1}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              justifyContent={isMobile ? "flex-start" : "center"}
              onClick={() => navigateTo("/home")}
            >
              <img src={logo} alt="Logo" style={{ height: 40 }} />
              {!isMedium && (
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  VANGUARD
                </Typography>
              )}
            </Grid>

            {isMedium ? (
              // Mobile Menu
              <Grid item md={10} justifyContent={"flex-end"}>
                <IconButton
                  aria-describedby={id}
                  variant="contained"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenuOpen}
                  sx={{
                    ml: 2,
                    backgroundColor: "#19374F",
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={() => navigateTo("/home")}>
                    Inicio
                  </MenuItem>
                  {/* <MenuItem onClick={handleMenuClose}>Paquetes</MenuItem> */}
                  {/* <MenuItem onClick={handleMenuClose}>Portafolio</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Blog</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Contacto</MenuItem> */}
                  <MenuItem onClick={handleMenuClose}>
                    <Button
                      sx={{
                        bgcolor: Colors.button,
                        maxWidth: 150,
                      }}
                      variant="contained"
                      onPress={() =>
                        openInNewTab("https://academy.vanguardglobal.pro/")
                      }
                    >
                      Academia
                    </Button>
                  </MenuItem>
                  {/* <MenuItem onClick={handleMenuClose}>
                    <Button
                      sx={{
                        bgcolor: Colors.button,
                        maxWidth: 150,
                      }}
                      variant="contained"
                    >
                      Portal IB
                    </Button>
                  </MenuItem> */}
                </Popover>
              </Grid>
            ) : (
              // Desktop Menu
              <Grid
                item
                md={4}
                lg={5}
                sm={10}
                container
                justifyContent={"flex-start"}
              >
                <Button
                  color="inherit"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  onClick={() => navigateTo("/home")}
                >
                  Inicio
                </Button>
                {/* <Button
                  color="inherit"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <li>Paquetes</li>
                </Button> */}
                {/* <Button
                  color="inherit"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <li>Portafolio</li>
                </Button>
                <Button
                  color="inherit"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <li>Blog</li>
                </Button>
                <Button
                  color="inherit"
                  style={{
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <li>Contacto</li>
                </Button> */}
              </Grid>
            )}

            <Grid
              item
              md={4.5}
              lg={4}
              xl={3}
              container
              columnGap={1}
              justifyContent={isMobile ? "flex-start" : "flex-end"}
              sx={{
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
            >
              <VgButton
                title={"Academia"}
                bgColor={Colors.yellowAcademy}
                bgColorB={Colors.yellowDark}
                height={35}
                fontSize={14}
                width={150}
                borderRadius={"12px"}
                onPress={() =>
                  openInNewTab(" https://academy.vanguardglobal.pro/")
                }
              />

              {/* <VgButton
                title={"  Portal IB"}
                bgColor={Colors.yellowAcademy}
                bgColorB={Colors.yellowDark}
                height={35}
                fontSize={14}
                width={150}
                borderRadius={"12px"}
                onPress={() =>
                  openInNewTab(" https://vanguardacademy.web.app/")
                }
              /> */}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
