import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { styles } from "../../../assets/styles/styles";
import { VanguardNavbar } from "../../components/shared/VanguardNavbar";

export const PrivacyPolicy = () => {
  const theme = useTheme();
  return (
    <>
      {/* <VanguardNavbar /> */}

      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: 10 }}>
        <Box
          sx={{
            width: "100%",

            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
              minHeight: 0,
            },
          }}
          mb={20}
        >
          <Grid container sx={styles.titleGridContainer}>
            <Box sx={styles.titleCard}>
              <div className="titleIcon">
                <Typography mr={"auto"} ml={2} sx={styles.boldTextLight}>
                  Politicas de privacidad
                </Typography>
              </div>
            </Box>
          </Grid>
          <Box mt={4} mb={4}>
            <ol className="termsList">
              <li>
                Nuestra Política de privacidad determina la manera en que
                tratamos toda la información que captamos de nuestros miembros y
                visitantes (en los sucesivo, El Miembro), quienes utilizan
                nuestros servicios y/o acceden a nuestra plataforma en modo web
                o modo App (utilizando una API propia de la plataforma o una API
                de terceros) y/o nos contacten a través de nuestros canales de
                marketing y/o nuestras redes sociales. El presente documento
                tiene como finalidad indicarle al Miembro qué información
                recopilamos en estos espacios, cómo utilizamos dicha información
                y los derechos que tiene asociados con la mencionada
                información. Siempre que nos referimos a “VANGUARD”, “Nosotros”,
                “Sitio Web” o “la Plataforma”, nos referimos a VANGUARD GROUP.
                Cada vez que VANGUARD solicita información para permitirle al
                Miembro disfrutar y/o acceder de un servicio, le indicará al
                mismo cuál información es de carácter obligatorios y cuál es de
                carácter opcional. En caso que el Miembro no provea la
                información señalada como obligatoria, no será posible de
                prestar y/o conceder el acceso al servicio solicitado por el
                Miembro.
              </li>
            </ol>
          </Box>
          <ol className="termsList">
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                1. INFORMACIÓN QUE RECOPILAMOS
              </Typography>
            </Box>
            <li>
              Entre la información que recopilamos se puede encontrar, pero sin
              limitarse, los siguientes tipos:
            </li>
            <li>
              De tipo personal: Se refiere a su nombre completo, fecha de
              nacimiento, edad, nacionalidad, sexo, firma, facturas de servicios
              públicos, fotografías, números de teléfono, dirección particular o
              correo electrónico
            </li>
            <li>
              De tipo formal: Número de identificación fiscal, número de
              pasaporte, datos de la licencia de conducir, datos del documento
              nacional de identidad, tarjetas de identificación con fotografía o
              información de visado
            </li>
            <li>
              De tipo financiera: Información de la cuenta bancaria a la cual
              recibirá pagos, información sobre su tarjeta de crédito
              (únicamente al momento de realizar los pagos para adquirir los
              Servicios prestados de forma directa o indirecta por VANGUARD) y
              los datos requeridos por ley de persona que realiza la
              transacción. Identificadores de Internet: Datos sobre
              seguimiento/geolocalización, huella digital del explorador, SO,
              nombre y versión del explorador o direcciones IP personales.
            </li>
            <li>
              Datos de uso: Respuestas a encuestas, información proporcionada a
              nuestro equipo de servicio al cliente, publicaciones en redes
              sociales públicas, datos de autentificación, preguntas de
              seguridad, identificación del Miembro, datos de clickstream y
              otros datos recopilados a través de cookies y tecnologías
              similares.
            </li>
            <li>
              Datos de registros y cuentas de clientes: Entre ellos se incluye
              información que nos facilita para crear su cuenta con nosotros o
              registrarse en eventos, seminarios web, encuestas, etc., y pueden
              incluir nombre y apellidos, dirección de facturación, una
              contraseña y una dirección de correo electrónico válida.
            </li>
            <li>
              Datos de servicio (incluidos datos de sesión y uso): Cuando visita
              nuestros Sitio web y utiliza nuestros Servicios, recibimos
              información introducida voluntariamente por usted u otras personas
              o registrada de forma automática por el Sitio Web o el propio
              Servicio (por ejemplo, duración de la sesión, uso de cámaras web,
              información de conexión, etc.). También podemos recopilar datos de
              uso y registro sobre cómo se accede a los Servicios y cómo se
              utilizan, lo que incluye información sobre el dispositivo que
              utiliza para acceder a los Servicios, direcciones IP, información
              de ubicación, ajustes de idioma, qué sistema operativo utiliza,
              identificadores únicos de dispositivo y otros datos diagnósticos
              para ayudarnos a dar soporte a los Servicios y mejorarlos.
            </li>
            <li>
              Datos de servicio (incluidos datos de sesión y uso): Cuando visita
              nuestros Sitio web y utiliza nuestros Servicios, recibimos
              información introducida voluntariamente por usted u otras personas
              o registrada de forma automática por el Sitio Web o el propio
              Servicio (por ejemplo, duración de la sesión, uso de cámaras web,
              información de conexión, etc.). También podemos recopilar datos de
              uso y registro sobre cómo se accede a los Servicios y cómo se
              utilizan, lo que incluye información sobre el dispositivo que
              utiliza para acceder a los Servicios, direcciones IP, información
              de ubicación, ajustes de idioma, qué sistema operativo utiliza,
              identificadores únicos de dispositivo y otros datos diagnósticos
              para ayudarnos a dar soporte a los Servicios y mejorarlos.
            </li>
            <li>
              Datos de terceros: Podemos recibir información sobre usted de
              otras fuentes, entre las que se incluyen bases de datos
              públicamente disponibles o terceros a los que hayamos adquirido
              datos, y combinar estos datos con información que ya tengamos
              sobre usted. También podemos recibir información de otras filiales
              que formen parte de nuestro grupo corporativo. Estos datos de
              terceros se obtienen en virtud de nuestros intereses empresariales
              legítimos y nos ayudan a actualizar, ampliar y analizar nuestros
              registros, identificar nuevos posibles clientes para realizar
              labores de marketing y proporcionar productos y servicios que
              puedan interesarle.
            </li>
            <li>
              Información de ubicación: Recopilamos su información de ubicación
              para prestar el Servicio y ofrecer soporte en dicho Servicio y
              para prevenir el fraude y supervisar la seguridad. Si desea que no
              recopilemos ni utilicemos su información de ubicación, deshabilite
              los servicios de ubicación en su dispositivo móvil.
            </li>
            <li>
              Información de dispositivos: Cuando utiliza nuestros Servicios en
              línea y móviles, podemos recopilar automáticamente información
              sobre el tipo de dispositivo que utiliza (por ejemplo, la versión
              del sistema operativo o el identificador del dispositivo o
              "UDID"). Cualquier información adicional que sea necesaria para
              poder prestar cualquier servicio o información solicitada por el
              Miembro o para el cumplimiento de obligaciones legales.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                2. OBTENCIÓN DE INFORMACIÓN
              </Typography>
            </Box>
            <li>Podemos obtener su información de dos formas:</li>
            <li>2.1. Por el Miembro</li>
            <li>
              2.2. La información que usted nos proporciona puede ser tratada
              por VANGUARD, bien porque usted ha proporcionado dichos datos en
              los formularios que debe cumplimentar para ser Miembro de VANGUARD
              o bien porque se derivan de las interacciones o uso que usted
              efectúe en el Sitio Web o App de VANGUARD o por los Servicios que
              se ponen a su disposición. Estos datos no revelan necesariamente
              su identidad de manera directa, pero puede incluir información
              sobre el dispositivo específico que usa, como el modelo de
              hardware, la identificación del dispositivo, la versión del
              sistema operativo, el software del explorador web (como Firefox,
              Safari o Internet Explorer) y el identificador de su
              dispositivo/dirección MAC/dirección de protocolo de Internet (IP).
              Por ejemplo, recibimos y registramos automáticamente información
              en los registros de nuestro servidor de su explorador, que incluye
              cómo llegó a los Servicios y los usó; su dirección IP; el tipo de
              dispositivo y los números de identificación únicos de los
              dispositivos; información de eventos del dispositivo (como
              bloqueos, actividad del sistema y configuración de hardware, tipo
              de explorador, lenguaje del explorador, la fecha y la hora de su
              solicitud y URL de referencia), ubicación geográfica general (es
              decir, país o ciudad) y otros datos técnicos recopilados a través
              de cookies, etiquetas de píxeles y otras tecnologías similares que
              identifican a su explorador de manera única. Es posible que
              también recopilemos información sobre cómo su dispositivo ha
              interactuado con nuestro Sitio Web, lo que incluye las páginas a
              las que accedió y los enlaces sobre los que hizo clic. Es posible
              que usemos identificadores para reconocerlo cuando llegue a
              nuestro Sitio Web a través de un enlace externo, por ejemplo, un
              enlace que aparezca en el sitio de un tercero. Por fuentes
              externas Ocasionalmente, podemos obtener información sobre usted
              de fuentes de terceros tal como lo requiere o permite la
              legislación vigente, como bases de datos públicamente disponibles
              o terceros a los que hayamos adquirido datos, y combinar estos
              datos con información que ya tengamos sobre usted. También podemos
              recibir información de otras filiales que formen parte de nuestro
              grupo corporativo. Estos datos de terceros se obtienen en virtud
              de nuestros intereses empresariales legítimos y nos ayudan a
              actualizar, ampliar y analizar nuestros registros, identificar
              nuevos posibles clientes para realizar labores de marketing y
              proporcionar productos y servicios que puedan interesarle.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                3. MOTIVO PARA LA OBTENCIÓN DE INFORMACIÓN POLÍTICA DE
                PRIVACIDAD DE VANGUARD GROUP
              </Typography>
            </Box>
            <li>3.1 Entre los diferentes motivos, tratamos sus datos para:</li>
            <li>
              3.1.1 Ejecución de contrato: El tratamiento de los datos, conforme
              se ha indicado, es necesario para la prestación de los servicios
              de VANGUARD y contratados por el Miembro.
            </li>
            <li>
              3.1.2 Cumplimiento de una obligación legal: VANGUARD requiere
              llevar un registro propio de sus Miembros según el alcance de lo
              establecido por la ley.
            </li>
            <li>
              3.1.3 Interés legítimo del responsable: Algunos de los
              tratamientos que se efectúan, tales como la remisión de
              publicidad, se realizan sobre la base del interés legítimo de la
              empresa.
            </li>
            <li>
              3.1.4 Consentimiento: En todo caso usted presta su consentimiento
              a la presente política de privacidad en la utilización de nuestros
              servicios.{" "}
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                4. VERACIDAD DE LOS DATOS POLÍTICA DE PRIVACIDAD DE VANGUARD
                GROUP
              </Typography>
            </Box>
            <li>
              El Miembro es responsable de la veracidad de sus datos. De esta
              manera, se compromete a no proveer información falsa y a proceder
              a rectificar sus datos, siempre que sea necesario. Cualquier
              cambio es posible realizar a través de las herramientas que
              VANGUARD coloca a disposición del Miembro. Lo anterior se debe
              hacer a través del área única, privada y segura que tiene el
              Miembro en su cuenta accediendo al Sitio Web o modo App de
              VANGUARD. Es por esto que el Miembro deba ser especialmente
              cuidadoso en la guardia y custodia de estas claves. El Miembro,
              cuando introduce los datos personales de un tercero, manifiesta
              que se encuentra autorizado por dicho tercero para su
              introducción. Cuando nos referimos a "información personal" en
              esta política, nos referimos a datos personales, información
              personal o equivalente de acuerdo con la legislación aplicable.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                5. FINALIDAD DE UTILIZACIÓN DE LOS DATOS RECOPILADOS
              </Typography>
            </Box>
            <li>
              VANGUARD trata sus datos, con un alcance variable según la
              utilización que se haga de nuestros servicios y por una variedad
              de razones y finalidades, principalmente para:
            </li>
            <li>
              5.1 Poder contratar con VANGUARD, usar sus servicios y ejecutar
              las relaciones contractuales y peticiones correctamente
              realizadas.
            </li>
            <li>
              5.2 Decidir si otorgarle y facilitarle el(los) Servicio(s)
              solicitado(s) por el Miembro.
            </li>
            <li>
              5.3 Ofrecerle productos y/o servicios propios y/o de terceros, lo
              que podremos hacer también a través de terceras personas.
            </li>
            <li>
              5.4 Enviarle ofertas comerciales, publicidad e información general
              de los Servicios de VANGUARD y/o de terceros (por ejemplo, con
              empresas con las que tengamos asociaciones o alianzas
              comerciales).
            </li>
            <li>5.5 Enviarle invitaciones y encuestas.</li>
            <li>
              5.6 Para fines de investigación y desarrollo de nuevos Servicios.
            </li>
            <li>
              5.7 Para personalizar, medir y mejorar los Servicios de VANGUARD,
              además del contenido y el diseño de nuestro Sitio Web y App.
            </li>
            <li>
              5.8 Para desarrollar cualquier actividad necesaria para la
              correcto funcionamiento y/o prestación de los Servicios ofrecidos.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                6. TRANSFERENCIA DE INFORMACIÓN A TERCEROS POLÍTICA DE
                PRIVACIDAD DE VANGUARD GROUP
              </Typography>
            </Box>
            <li>
              Podemos compartir su información personal con proveedores de
              servicios externos, socios empresariales, aliados comerciales,
              filiales de nuestra estructura corporativa y en los casos que sea
              necesario con fines legales. Los proveedores de servicios externos
              solo tienen acceso a información personal si es necesario para
              desempeñar sus funciones y deben tratar la información personal de
              acuerdo con esta Política de Privacidad.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                7. COMUNICACIÓN DE PARTE DE VANGUARD
              </Typography>
            </Box>
            <li>
              7.1 A solicitud del Miembro (para responder dudas, atender
              problemas o solicitudes, etc.)
            </li>
            <li>
              7.2 Enviarle mensajes y correos electrónicos importantes
              administrativos o sobre transacciones en relación con los
              Servicios.
            </li>
            <li>
              7.3 Enviar correos electrónicos sobre noticias o productos de
              VANGUARD que consideremos que pueden interesarle, nuestros o de
              nuestros aliados comerciales, siempre de acuerdo con un interés
              empresarial legítimo o a solicitud suya.
            </li>
            <li>7.4 Realizar encuestas.</li>
            <li>
              7.5 Ofrecer incentivos y programas de referencias, que le permiten
              utilizar vínculos de URL, texto o correo electrónico que puede
              compartir con amigos o compañeros.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                8. CONSERVACIÓN DE LOS DATOS
              </Typography>
            </Box>
            <li>
              Solo conservamos una cantidad limitada de datos personales, con
              una base adecuada y legal, durante el tiempo necesario para los
              fines empresariales con los que se recopilaron o mientras es
              necesario para cumplir nuestras obligaciones legales, resolver
              disputas y aplicar nuestros acuerdos
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                9. POLÍTICA DE PRIVACIDAD DE VANGUARD GROUP
              </Typography>
            </Box>
            <li>
              Una vez transcurrido ese plazo, eliminamos o anonimizamos los
              datos de acuerdo con nuestras políticas o nuestros procedimientos
              de conservación de datos. Los datos se conservarán mientras usted
              sea Miembro de nuestros Servicios, hasta que solicite su supresión
              y, en todo caso, durante el plazo necesario para el cumplimiento
              de obligaciones legales Esto es necesario para obstaculizar el
              fraude, ya que se garantiza que las personas que intentan cometer
              un fraude no podrán evitar la detección solo cerrando su cuenta y
              abriendo una cuenta nueva. Sin embargo, si cierra su cuenta, no
              usaremos su información personal para ningún otro fin, ni se
              venderá a terceros ni se compartirá con estos, excepto en la
              medida de lo necesario para evitar el fraude y asistir a las
              autoridades para la imposición del cumplimiento de la jurisdicción
              correspondiente, como lo exija la legislación correspondiente y/o
              de acuerdo con esta Política de privacidad.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                10. DERECHOS DEL MIEMBRO
              </Typography>
            </Box>
            <li>
              Cualquier Miembro tiene derecho a obtener confirmación sobre si en
              VANGUARD estamos tratando, o no, datos personales que les
              conciernan. Las personas interesadas tienen derecho a acceder a
              sus datos personales de manera gratuita, así como a solicitar la
              rectificación de los datos inexactos o, en su caso, solicitar su
              supresión cuando, entre otros motivos, los datos ya no sean
              necesarios para los fines que fueron recogidos. Igualmente tiene
              derecho a la portabilidad de sus datos. En determinadas
              circunstancias, los interesados podrán solicitar la limitación del
              tratamiento de sus datos, en cuyo caso únicamente los
              conservaremos para el ejercicio o la defensa de reclamaciones. De
              igual manera, los interesados podrán oponerse al tratamiento de
              sus datos. En este caso, VANGUARD dejará de tratar los datos,
              salvo por motivos legítimos imperiosos, o el ejercicio o la
              defensa de posibles reclamaciones. Cuando se realice el envío de
              comunicaciones comerciales utilizando como base jurídica el
              interés legítimo del responsable, el interesado podrá oponerse al
              tratamiento de sus datos con ese fin. Si ha otorgado su
              consentimiento para alguna finalidad concreta, tiene derecho a
              retirar el consentimiento otorgado en cualquier momento, sin que
              ello afecte a la licitud del tratamiento basado en el
              consentimiento previo a su retirada. Los Miembros podrán ejercer
              los derechos contenidos en la presente Política y aquellos
              garantizados por las leyes aplicables a través de los correos
              electrónicos o direcciones físicas de contacto previamente
              relacionados.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                11.PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE HABEAS DATA
              </Typography>
            </Box>
            <li>
              En cumplimiento de las normas sobre protección de datos
              personales, VANGUARD presenta el procedimiento y requisitos
              mínimos para el ejercicio de sus derechos: Para la radicación y
              atención de su solicitud le solicitamos suministrar la siguiente
              información:
            </li>
            <li>11.1. Nombre completo y apellidos</li>
            <li>
              11.2. Datos de contacto (Dirección física y/o electrónica y
              teléfonos de contacto)
            </li>
            <li>11.3. Medios para recibir respuesta a su solicitud</li>
            <li>
              10.4. Motivo(s)/hecho(s) que dan lugar al reclamo con una breve
              descripción del derecho que desea ejercer (conocer, actualizar,
              rectificar, solicitar prueba de la autorización otorgada,
              revocarla, suprimir, acceder a la información)
            </li>
            <li>
              11.5. Firma (si aplica) y número de identificación. El término
              máximo previsto por la ley para resolver su reclamación es de
              quince (15) días hábiles, contado a partir del día siguiente a la
              fecha de su recibo. Cuando no fuere posible atender el reclamo
              dentro de dicho término, VANGUARD informará al interesado los
              motivos de la demora y la fecha en que se atenderá su reclamo, la
              cual en ningún caso podrá superar los ocho (8) días hábiles
              siguientes al vencimiento del primer término.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                12.MENORES DE EDAD
              </Typography>
            </Box>
            <li>
              POLÍTICA DE PRIVACIDAD DE VANGUARD GROUP No solicitamos ni
              pretendemos recopilar, a sabiendas, la de información personal de
              personas menores de 18 años de edad. Si encontramos o descubrimos
              que hemos recibido información personal de un menor de 18 años de
              edad, VANGUARD considerará responsable de sus actos a sus padres o
              tutores legales y se procederá a cancelar la cuenta
              correspondiente.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                13.CAMBIOS Y VIGENCIA
              </Typography>
            </Box>
            <li>
              Podemos actualizar esta Política de Privacidad para reflejar
              cambios en nuestras prácticas relacionadas con la administración
              de la información, su recopilación y/o su uso. Si realizamos
              cambios importantes que influyan de forma significativa y adversa
              sobre su privacidad, avisaremos a través del Sitio Web de VANGUARD
              y a través de notificación por correo electrónico (enviado a la
              dirección de correo electrónico especificada en su cuenta) para su
              nueva aceptación antes de que el o los cambios entren en vigor.
            </li>
            <li>
              Es deber del Miembro revisar regularmente el Sitio Web de
              VANGUARD. Nos reservamos el derecho de modificar esta Política de
              privacidad en cualquier momento y, cuando lo exijan las leyes
              aplicables y autoridades competentes; le notificaremos los cambios
              que se efectúen a esta Política de Privacidad. Si hacemos cambios
              sustanciales, le notificaremos por correo electrónico (enviado a
              la dirección de correo electrónico especificada en su cuenta) o
              mediante un aviso en nuestros Servicios antes de que el cambio
              entre en vigor.
            </li>
            <Box mt={2} mb={2}>
              <Typography
                mr={"auto"}
                sx={[styles.boldTextTerms, { color: "white" }]}
              >
                Última actualización 12 de diciembre de 2023.
              </Typography>
            </Box>
          </ol>
        </Box>
      </Container>
    </>
  );
};
