import {
  GET_MASTERCLASS,
  GET_SCHEDULE_EDUCATIVES,
  GET_SCHEDULE_ONECORE,
  GET_SCHEDULE_OPERATIVES,
  GET_SESSIONS,
  SET_IS_LOADING,
  SET_SCHEDULE,
} from "../actions/schedules";
import { types } from "../types/types";

const initialState = {
  schedules: [],
  onecore: null,
  educatives: null,
  operatives: null,
  masterclass: [],
  isLoading: false,
  sessions: [],
};

export const schedulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHEDULE:
      return {
        ...state,
        schedules: action.payload.schedules,
        // displayName: action.payload.displayName,
      };
    case GET_SCHEDULE_ONECORE:
      return {
        ...state,
        onecore: action.onecore,
      };
    case GET_SCHEDULE_OPERATIVES:
      return {
        ...state,
        operatives: action.operatives,
      };
    case GET_SCHEDULE_EDUCATIVES:
      return {
        ...state,
        educatives: action.educatives,
      };
    case GET_MASTERCLASS:
      return {
        ...state,
        masterclass: action.payload.masterclass,
      };
    case GET_SESSIONS:
      return {
        ...state,
        sessions: action.payload.sessions,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        // displayName: action.payload.displayName,
      };

    default:
      return state;
  }
};
