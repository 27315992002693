import { types } from "../types/types";

const initialState = {
users: [],
  isLoading: false,
};


export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.getUsers:
        return {
          ...state,
          users: action.payload.users,
          // displayName: action.payload.displayName,
        };
  
      case types.isLoadingUsers:
        return {
          ...state,
          isLoading: action.payload.isLoading,
          // displayName: action.payload.displayName,
        };
  
      default:
        return state;
    }
  };
  