import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { styles } from "../../../assets/styles/styles";
import { VanguardNavbar } from "../../components/shared/VanguardNavbar";

export const Terms = () => {
  const theme = useTheme();
  return (
    <>
      <VanguardNavbar />

      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: 10 }}>
        <Box
          sx={{
            width: "100%",

            [theme.breakpoints.down("md")]: {
              justifyContent: "center",
              minHeight: 0,
            },
          }}
          mb={20}
        >
          <Grid container sx={styles.titleGridContainer}>
            <Box sx={styles.titleCard}>
              <div className="titleIcon">
                <Typography mr={"auto"} ml={2} sx={styles.boldTextLight}>
                  TÉRMINOS DE USO DE VANGUARD ACEPTACIÓN DE LAS CONDICIONES
                  MEDIANTE EL USO:
                </Typography>
              </div>
            </Box>
          </Grid>
          <Box mt={4} mb={4}>
            <ol className="termsList">
              <li>
                Este Acuerdo debe ser completado, entendido y aceptado por una
                persona mayor de 18 años. Si un padre o tutor desea permitir que
                una persona menor de 18 años acceda a este Sitio, debe enviar un
                correo electrónico a la Compañía con su permiso explícito y
                aceptación de la responsabilidad legal total. Si aún no tiene 18
                años o está accediendo a este Sitio desde cualquier país donde
                este material esté prohibido, salga ahora ya que no tiene la
                autorización adecuada.
              </li>
            </ol>
          </Box>
          <ol className="termsList">
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                1. DEBE SER MAYOR DE 18 AÑOS PARA ACEPTAR ESTE ACUERDO Y
                UTILIZAR ESTE SITIO:
              </Typography>
            </Box>
            <li>
              Este Acuerdo debe ser completado, entendido y aceptado por una
              persona mayor de 18 años. Si un padre o tutor desea permitir que
              una persona menor de 18 años acceda a este Sitio, debe enviar un
              correo electrónico a la Compañía con su permiso explícito y
              aceptación de la responsabilidad legal total. Si aún no tiene 18
              años o está accediendo a este Sitio desde cualquier país donde
              este material esté prohibido, salga ahora ya que no tiene la
              autorización adecuada.
            </li>
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                2. LICENCIA PARA UTILIZAR ESTE SITIO:
              </Typography>
            </Box>
            <li>
              Con su acuerdo, la Compañía le otorga una licencia limitada no
              exclusiva, intransferible y revocable para usar este Sitio en
              estricta conformidad con los términos y condiciones de este
              Acuerdo. Usted acepta no realizar declaraciones falsas o
              fraudulentas mientras utiliza este Sitio. Usted reconoce y acepta
              que todo el contenido y los servicios disponibles en este Sitio
              son propiedad de la Compañía y están protegidos por derechos de
              autor, marcas registradas, marcas de servicio, patentes, secretos
              comerciales y otros derechos y leyes de propiedad, en los EAU e
              internacionalmente. Todos los derechos no otorgados expresamente
              en este documento están totalmente reservados por la Compañía, sus
              anunciantes y licenciantes. Usted acepta pagar todas y cada una de
              las compras y servicios utilizando su nombre y tarjeta de crédito
              a través de este Sitio, no cuestionar dichos cargos y pagar todos
              los cobros y/o los honorarios de los abogados que resulten de
              cualquier falta de pago.
            </li>
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                3. PRIVACIDAD:
              </Typography>
            </Box>
            <li>
              Su visita a este Sitio también se rige por nuestra Política de
              Privacidad. Revise nuestra Política de privacidad que se encuentra
              en el pie de página de nuestro Sitio.
            </li>
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                4. RESTRICCIONES DE LA LICENCIA:
              </Typography>
            </Box>
            <li>
              a. Uso: Salvo que se permita explícitamente, usted acepta no
              guardar, descargar, cortar y pegar, vender, licenciar, alquilar,
              arrendar, modificar, distribuir, copiar, reproducir, transmitir,
              mostrar públicamente, realizar públicamente, publicar, adaptar,
              editar o crear trabajos derivados de materiales de este Sitio. Se
              prohíbe la recuperación sistemática de datos u otro contenido de
              este Sitio para crear o compilar, directa o indirectamente, una
              colección, base de datos o directorio sin el permiso por escrito
              de la Compañía. Además, se prohíbe el uso del contenido o los
              materiales para cualquier propósito que no esté expresamente
              permitido en este Acuerdo.
            </li>
            <li>
              b. Seguridad: usted acepta que si la Compañía le proporciona un
              Nombre de usuario y una Contraseña, deberá hacer todo lo posible
              para evitar el acceso a este Sitio a través de su Nombre de
              usuario y Contraseña por parte de cualquier otra persona que no
              sea usted, lo que incluye, entre otros, mantener dicha información
              estrictamente confidencial, notificando a la Compañía de inmediato
              si descubre la pérdida o el acceso a dicha información por parte
              de una parte no autorizada y mediante el uso de un Nombre de
              usuario y una Contraseña seguros que un tercero no pueda adivinar
              fácilmente. Usted autoriza a la Compañía a confiar en su Nombre de
              usuario y Contraseña para identificarlo cuando utiliza los
              servicios de la Compañía. Usted acepta que no intentará
              desensamblar, descompilar, descompilar, desensamblar, traducir o
              alterar de otro modo ningún código ejecutable, contenidos o
              materiales recibidos a través de este Sitio. Usted comprende que
              es probable que dichas acciones lo expongan a sanciones legales
              civiles y penales graves y que la Compañía perseguirá dichas
              sanciones en toda la extensión de la ley para proteger sus
              derechos y los derechos de sus otros licenciatarios.
            </li>
            <li>
              c. Errores y correcciones: Si bien hacemos esfuerzos razonables
              para incluir información precisa y actual en nuestro Sitio, no
              garantizamos ni declaramos que el Sitio estará libre de errores.
              Los errores de entrada de datos u otros problemas técnicos a veces
              pueden dar como resultado que se muestre información inexacta. Nos
              reservamos el derecho de corregir cualquier inexactitud o error
              tipográfico en nuestro Sitio, incluidos los precios y la
              disponibilidad de productos y servicios, y no seremos responsables
              de dichos errores. También podemos realizar mejoras y/o cambios en
              las características, la funcionalidad o el contenido del Sitio en
              cualquier momento. Si ve alguna información o descripción que cree
              que es incorrecta, comuníquese con nosotros y la verificaremos por
              usted.
            </li>
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                6. ENLACES A OTROS SITIOS WEB:
              </Typography>
            </Box>
            <li>
              Nuestro sitio contiene enlaces a otros sitios web para su
              información y conveniencia, o para proporcionar compras
              adicionales de otros bienes y servicios a través de nuestros
              socios comerciales y de servicios. Estos sitios web de terceros
              son responsables y se comprometen a mantener sus propios términos
              de uso del sitio. Le sugerimos que revise cuidadosamente los
              términos de uso de cada sitio al que elija acceder desde nuestro
              Sitio.
            </li>
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                7. OTORGAMIENTO DE LICENCIA DE MIEMBRO AL SITIO:
              </Typography>
            </Box>
            <li>
              Excepto con respecto a la información personal, toda la
              información que publique en este Sitio o comunique a la Compañía a
              través de este Sitio (colectivamente, "Envíos") será para siempre
              propiedad de la Compañía. La Compañía no tratará ningún envío como
              confidencial y no incurrirá en ninguna responsabilidad como
              resultado de las similitudes que puedan aparecer en los servicios
              o productos futuros de la Compañía. Sin copia, la Compañía tendrá
              la propiedad exclusiva de todos los derechos presentes y futuros
              existentes sobre cualquier Presentación de todo tipo y naturaleza
              en todas partes. Usted reconoce que es completamente responsable
              del mensaje, incluida su legalidad, confiabilidad, adecuación,
              originalidad y derechos de autor. Por la presente declara y
              garantiza que su envío no infringe los derechos de ningún tercero.
            </li>
            <Box mt={4} mb={4}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                8. CONDUCTA DEL MIEMBRO:
              </Typography>
            </Box>
            <li>
              a. sea ilegal, amenazante, abusivo, acosador, difamatorio,
              calumnioso, engañoso, fraudulento, invasivo de la privacidad de
              otra persona, agraviante, obsceno, sexualmente explícito o
              gráfico, o que infrinja de otro modo las reglas o políticas de
              este Sitio
            </li>
            <li>
              b. infrinja cualquier patente, marca comercial, marca de servicio,
              secreto comercial, derecho de autor, derecho moral, derecho de
              publicidad, privacidad u otro derecho de propiedad de cualquier
              parte
            </li>
            <li>
              c. constituye publicidad no autorizada o no solicitada, correo
              basura o masivo (también conocido como "spamming"), cartas en
              cadena, cualquier otra forma de solicitud no autorizada, o
              cualquier forma de lotería o apuestas.
            </li>
            <li>
              d. contiene virus de software o cualquier otro código de
              computadora, archivos o programas que están diseñados o destinados
              a interrumpir, dañar o limitar el funcionamiento de cualquier
              software, hardware o equipo de telecomunicaciones o para dañar u
              obtener acceso no autorizado a cualquier dato u otra información
              de cualquier tercero.
            </li>
            <li>
              e. se hace pasar por cualquier persona o entidad, incluido
              cualquier empleado o representante de este Sitio, sus licenciantes
              o anunciantes. Además, acepta que no solicitará ni recopilará
              información sobre los usuarios de este Sitio ni utilizará dicha
              información con el fin de transmitir o facilitar la transmisión de
              comunicaciones o correos electrónicos masivos no solicitados para
              ningún otro propósito comercial propio o de un tercero
            </li>
            <li>
              f. Además, acepta que no solicitará ni recopilará información, ni
              intentará inducir a ningún contacto físico con cualquier persona
              de 18 años o menos sin el consentimiento apropiado de los padres.
              Por lo general, este Sitio no preselecciona, supervisa ni edita el
              contenido publicado por los usuarios de este Sitio. Sin embargo,
              este Sitio y sus agentes tienen el derecho, a su sola discreción,
              de eliminar cualquier contenido que, a juicio exclusivo de la
              Compañía, no cumpla con la postura de la Compañía sobre la
              conducta aceptable del usuario o sea dañino, objetable o inexacto.
              Este Sitio no es responsable de ninguna falla, demora, daño o
              resultado al eliminar dicho contenido.
            </li>
            <li>
              g. Usted acepta que su uso de este Sitio puede suspenderse o
              cancelarse inmediatamente después de recibir cualquier aviso que
              alegue que ha utilizado este Sitio en violación de estos Términos
              y condiciones y/o para cualquier propósito que viole cualquier ley
              local, estatal, federal o de otras naciones, incluida, entre
              otras, la publicación de información que pueda violar los derechos
              de terceros, que pueda difamar a un tercero, que pueda ser obscena
              o pornográfica, que pueda acosar o agredir a otros, que pueda
              violar la piratería informática u otras normas penales, etc. de
              sus agentes, funcionarios, directores, contratistas o empleados.
              En tal caso, usted acepta que el propietario de este Sitio puede
              divulgar su identidad e información de contacto, si así lo
              solicita un organismo gubernamental o encargado de hacer cumplir
              la ley o como resultado de una citación u otra acción legal, y el
              propietario de este Sitio no será responsable de los daños o
              resultado de una citación u otra acción legal, y el propietario de
              este Sitio no será responsable de los daños o resultados de los
              mismos, y usted acepta no iniciar ninguna acción o reclamo contra
              el propietario de este Sitio por dicha divulgación.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                9. DERECHOS DE PROPIEDAD INTELECTUAL:
              </Typography>
            </Box>
            <li>
              a. Derechos de autor: el diseño del Sitio, el texto, el contenido,
              la selección y la disposición de los elementos, la organización,
              los gráficos, la compilación, la traducción magnética, la
              conversión digital y otros asuntos relacionados con el Sitio están
              protegidos por las leyes de derechos de autor aplicables, TODOS
              LOS DERECHOS RESERVADOS. La publicación de dichos elementos en el
              Sitio no constituye una renuncia a ningún derecho sobre dichos
              elementos. Usted no adquiere derechos de propiedad sobre ninguno
              de dichos elementos vistos a través del Sitio. Salvo que se
              indique lo contrario en el presente, ninguno de estos elementos
              puede usarse, copiarse, reproducirse, descargarse, publicarse,
              mostrarse, transmitirse, mecánicamente, fotocopiarse, grabarse o
              de otro modo, sin el permiso previo por escrito de la Compañía.
            </li>
            <li>
              b. Marca comercial: el nombre, el logotipo y todos los nombres de
              productos, nombres de empresas y otros logotipos de VANGUARD o
              VANGUARD Rules, a menos que se indique lo contrario, son marcas
              comerciales, imagen comercial y nombre comercial de la empresa.
              Queda expresamente prohibido el uso o mal uso de cualquiera de las
              Marcas o cualquier otro material contenido en el Sitio, sin el
              permiso previo por escrito de su propietario.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                10.SITIOS DE TERCEROS:
              </Typography>
            </Box>
            <li>
              Puede ser transferido a comerciantes en línea u otros sitios de
              terceros a través de enlaces o marcos de este Sitio. Se le
              advierte que lea sus Términos y condiciones y/o Políticas de
              privacidad antes de utilizar dichos sitios. Estos sitios pueden
              contener información o material que es ilegal, irrazonable o que
              algunas personas pueden encontrar inapropiado u ofensivo. Estos
              otros sitios no están bajo el control de la Compañía y no son
              monitoreados ni revisados por la Compañía.
            </li>
            <li>
              La inclusión de dicho enlace o marco no implica la aprobación de
              este sitio por parte de la Compañía, sus anunciantes o
              licenciantes, ninguna asociación con sus operadores y se
              proporciona únicamente para su conveniencia. Usted acepta que la
              Compañía y sus licenciantes no tienen responsabilidad alguna por
              dichos sitios de terceros y el uso que usted haga de ellos.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                11. DESCARGO DE RESPONSABILIDAD DE GARANTÍAS:
              </Typography>
            </Box>
            <li>
              Vanguard, sus anunciantes y otorgantes de licencias no hacen
              ninguna declaración ni garantía sobre este Sitio, la idoneidad de
              la información contenida o recibida a través del uso de este
              Sitio, o cualquier servicio o producto recibido a través de este
              Sitio. Toda la información y el uso de este Sitio se proporcionan
              "tal cual" sin garantía de ningún tipo. Vanguard, los anunciantes
              y/o sus otorgantes de licencia renuncian a todas las garantías sin
              tener en cuenta este Sitio, la información contenida o recibida a
              través del uso de este Sitio, y cualquier servicio o producto
              recibido a través de este Sitio, incluidas todas las garantías
              expresas, legales e implícitas de comerciabilidad, idoneidad para
              un propósito particular, título y no infracción. Vanguard, los
              anunciantes y/o sus licenciantes no garantizan que los contenidos
              o cualquier información recibida a través de este Sitio sean
              precisos, confiables o correctos; que este Sitio estará disponible
              en cualquier momento o lugar en particular; que cualquier defecto
              o error será corregido; o que el contenido de cualquier
              información recibida a través de este Sitio esté libre de virus u
              otros componentes dañinos. Su uso de este Sitio es únicamente bajo
              su riesgo. El miembro acepta que no se ha basado en garantías,
              representaciones o declaraciones que no sean las contenidas en
              este acuerdo. Debido a que algunas jurisdicciones no permiten la
              exclusión de ciertas garantías, es posible que estas exclusiones
              no se apliquen a usted, pero se aplicarán en la medida máxima
              permitida por la ley de su jurisdicción.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                12.LIMITACIÓN DE RESPONSABILIDAD:
              </Typography>
            </Box>
            <li>
              Bajo ninguna circunstancia Vanguard, los anunciantes y/o sus
              licenciantes serán responsables de ningún daño directo, indirecto,
              punitivo, incidental, especial o consecuente que resulte del uso o
              la incapacidad de usar este Sitio. Esta limitación se aplica ya
              sea que la supuesta responsabilidad se base en un contrato,
              agravio, negligencia, responsabilidad estricta o cualquier otra
              base, incluso si Vanguard, los anunciantes y/o sus licenciantes
              han sido advertidos de la posibilidad de dicho daño. Debido a que
              algunas jurisdicciones no permiten la exclusión o limitación de
              daños incidentales o consecuentes, la responsabilidad de Vanguard,
              los anunciantes y/o sus respectivos otorgantes de licencias en
              dichas jurisdicciones se limitará a en la medida máxima permitida
              por la ley de su jurisdicción.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                13.INDEMNIZACIÓN:
              </Typography>
            </Box>
            <li>
              El miembro acepta defender, indemnizar y eximir de responsabilidad
              a Vanguard, sus anunciantes, licenciantes, subsidiarias y otras
              compañías afiliadas, y sus empleados, contratistas, funcionarios,
              agentes y directores de todas las responsabilidades, reclamos y
              gastos, incluidos los de abogado. tarifas, que surgen de su uso de
              este Sitio, o cualquier servicio, información o producto de este
              Sitio, o cualquier violación de este Acuerdo. Vanguard se reserva
              el derecho, a sus expensas, de asumir la defensa y el control
              exclusivos de cualquier asunto sujeto a indemnización por su
              parte, en cuyo caso deberá cooperar con Vanguard para hacer valer
              las defensas disponibles.
            </li>
            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                14.CUMPLIMIENTO LEGAL:
              </Typography>
            </Box>
            <li>
              Vanguard puede suspender o rescindir este Acuerdo o el uso del
              Miembro inmediatamente después de recibir cualquier aviso que
              alegue que el Miembro ha utilizado este Sitio para cualquier
              propósito que viole cualquier ley local, estatal, federal o de
              otras naciones, incluyendo pero no limitado a la publicación de
              información que pueda violar los derechos de terceros, que pueda
              difamar a un tercero, que pueda ser obscena o pornográfica, que
              pueda acosar o agredir a otros, que pueda violar la piratería u
              otras normas penales de su agente, funcionarios, directores,
              contratistas o empleados. En tal caso, Vanguard puede divulgar la
              identidad del Miembro y una citación u otra acción legal, y
              Vanguard no será responsable de los daños o resultados de los
              mismos y el Miembro acepta no iniciar ninguna acción o reclamo
              contra Vanguard por dicha divulgación.
            </li>

            <Box mt={2} mb={2}>
              <Typography mr={"auto"} sx={styles.boldTextTerms}>
                15.VARIOS:
              </Typography>
            </Box>

            <Box mt={2} mb={2}>
              <Typography
                mr={"auto"}
                sx={[styles.boldTextTerms, { color: "white" }]}
              >
                Este Acuerdo constituye el acuerdo completo entre las partes en
                relación con el objeto del mismo, reemplaza cualquier acuerdo
                anterior o contemporáneo (oral, escrito o electrónico) entre las
                partes y no se modificará excepto por acuerdo escrito firmado
                por un oficial de la compañía. Si alguna disposición de este
                Acuerdo está prohibida por ley y se considera inaplicable, las
                disposiciones restantes del presente no se verán afectadas, y
                este Acuerdo, en la medida de lo posible bajo la ley aplicable,
                continuará en pleno vigor y efecto como si dicha disposición
                inaplicable nunca hubiera constituido. una parte del presente y
                la disposición inaplicable se modificarán automáticamente para
                lograr mejor los objetivos de dicha disposición inaplicable
                dentro de los límites de la ley aplicable.
              </Typography>
            </Box>
          </ol>
        </Box>
      </Container>
    </>
  );
};
