import { Md10K } from "react-icons/md";
import img1 from "../../assets/img/Mock-up-pro-sintetic-power-lab.png";
import img2 from "../../assets/img/Mock-up-pro-academy.png";
import img3 from "../../assets/img/Mock-up-V-SIGNAL.png";
import teamImg from "../../assets/img/teamImg.png";
import teamImg2 from "../../assets/img/teamImg2.png";

import c1 from "../../assets/img/c1.png";
import c2 from "../../assets/img/c2.png";
import c3 from "../../assets/img/c3.png";
import c4 from "../../assets/img/c4.png";
import c5 from "../../assets/img/c5.png";
import c6 from "../../assets/img/c6.png";
import c7 from "../../assets/img/c7.png";
import c8 from "../../assets/img/c8.png";

import a1 from "../../assets/img/boss.png";
import a2 from "../../assets/img/lightbulb.png";
import a3 from "../../assets/img/medal.png";
import a4 from "../../assets/img/honest.png";

export const communityCards = [
  {
    id: 1,
    icon: (
      <img style={{ width: 48, height: 48 }} src={c1} alt="Community Icon 1" />
    ),
    text: "Rendimiento Histórico",
  },
  {
    id: 2,
    icon: (
      <img style={{ width: 48, height: 48 }} src={c2} alt="Community Icon 2" />
    ),
    text: "Transparencia Total",
  },
  {
    id: 3,
    icon: (
      <img style={{ width: 48, height: 48 }} src={c3} alt="Community Icon 3" />
    ),
    text: "Últimas Tecnologías",
  },
  {
    id: 4,
    icon: (
      <img style={{ width: 48, height: 48 }} src={c4} alt="Community Icon 4" />
    ),
    text: "Servicio de confianza",
  },
  {
    id: 5,
    icon: (
      <img style={{ width: 48, height: 48 }} src={c5} alt="Community Icon 5" />
    ),
    text: "24/7 Soporte",
  },
  {
    id: 6,
    icon: (
      <img style={{ width: 48, height: 48 }} src={c6} alt="Community Icon 6" />
    ),

    text: "Equipo de expertos",
  },
  {
    id: 7,
    icon: (
      <img style={{ width: 48, height: 48 }} src={c7} alt="Community Icon 7" />
    ),
    text: "Educación de alta calidad",
  },
  {
    id: 8,
    icon: (
      <img style={{ width: 48, height: 48 }} src={c8} alt="Community Icon 8" />
    ),
    text: "Datos y análisis",
  },
];

export const aboutCardData = [
  {
    id: 1,
    icon: (
      <img
        style={{ width: 64, height: 64, marginRight: 2 }}
        src={a1}
        alt="Community Icon 8"
      />
    ),
    text: "2k+",
    subtitle: "CLientes Felices",
  },
  {
    id: 2,
    icon: (
      <img
        style={{ width: 64, height: 64, marginRight: 2 }}
        src={a2}
        alt="Community Icon 8"
      />
    ),
    text: "+24",
    subtitle: "Horas de educación",
  },
  {
    id: 3,
    icon: (
      <img
        style={{ width: 64, height: 64, marginRight: 2 }}
        src={a3}
        alt="Community Icon 8"
      />
    ),
    text: "85+",
    subtitle: "Socios comerciales",
  },
  {
    id: 4,
    icon: (
      <img
        style={{ width: 64, height: 64, marginRight: 2 }}
        src={a4}
        alt="Community Icon 8"
      />
    ),
    text: "10+",
    subtitle: "Años de experiencia",
  },
];

export const academyData = [
  {
    id: 1,
    icon: <Md10K size={84} />,
    text: "SYNTHETIC POWER LAB",
    subtitle: "+25 HORAS, DE CLASES DE ÍNDICES SINTÉTICOS",
    img: img1,
  },
  {
    id: 2,
    icon: <Md10K size={84} />,
    text: "VANGUARD PRO ACADEMY",
    subtitle: "CONVIÉRTETE EN UN PROFESIONAL DEL TRADING",
    img: img2,
  },
  {
    id: 3,
    icon: <Md10K size={84} />,
    text: "VSIGNALS",
    subtitle: "APALÁNCATE DE LOS EXPERTOS CON LAS MEJORES SEÑALES",
    img: img3,
  },
];
export const services = [
  {
    id: 1,
    icon: <Md10K size={84} />,
    text: "PREMIUM ASSESTS",

    img: teamImg,
  },
  {
    id: 2,
    icon: <Md10K size={84} />,
    text: "ALGORITMOS",

    img: teamImg2,
  },
];
export const teamData = [
  {
    id: 1,

    title: "INSTITUTIONAL STRATEGY",
    img: teamImg,
  },
  {
    id: 2,
    title: "ALGORITMOS",
    text: "Utiliza nuestros algoritmos de  última tecnología y genera entre un 3% y un 8% mensual",
    text2: "Podrás conectar tu cuenta de forma automática a partir de $1,000",
  },
  {
    id: 3,

    title: "GOLDEN FEVER",
    img: teamImg2,
  },
];
