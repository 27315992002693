import Colors from "../../app/utils/Colors";
import theme from "./darkTheme";

export const styles = {
  white: "#fafafa",
  darkGreen: "#295252",
  cardColor: "#F3F3F3",
  grey: {
    0: "#f3f3f3",
    50: "#F3F6F9",
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  },
  cardStyle: {
    // backgroundColor: { xs: "secondary.light", sm: `${white}` },
    backgroundColor: Colors.bgLight,

    width: "100%",
    boxShadow: 3,

    borderRadius: 1,
  },
  wizardCard: {
    position: "relative",
    backgroundColor: "#4b4b4b",
    width: "100%",
    boxShadow: 3,
    minHeight: "75vh",
    borderRadius: 18,
    padding: 20,
    overflowX: "hidden",
    overflowY: "auto",
    mb: 4,
  },

  courseCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    boxShadow: 3,
    borderRadius: 1,
    backgroundColor: "#2d2d30",
    height: "520px",
    width: "340px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: 1.5,
    [theme.breakpoints.down("sm")]: {
      width: 230,
      height: "375px",
    },
  },
  courseCard2: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    boxShadow: 3,
    borderRadius: 1,
    backgroundColor: "#2d2d30",
    height: "200px",
    width: "200px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: 1.5,
    [theme.breakpoints.down("sm")]: {
      height: "175px",
      width: "175px",
    },
  },

  cardTitleStyle: {
    fontWeight: "600 !important",
    fontSize: "1.4rem !important",
  },
  tableCard: {
    width: "95%",
    backgroundColor: "#fafafa",
    boxShadow: 2,
    // p: 3,
    marginBottom: 3,
    borderRadius: "18px",
  },
  innerCardStyle: {
    margin: "0px auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 1,
  },
  smallCardStyle: {
    backgroundColor: "#fafafa",
    boxShadow: 3,
    borderRadius: 1,
    p: 1,
    display: "flex",
    flexDirection: "column",
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderRadius: "24px",
    borderColor: "#eeeeee",
    // borderStyle: "dashed",
    // backgroundColor: "#fafafa",
    marginTop: 5,
    maxHeight: 140,
    justifyContent: "center",
  },

  modalThumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    borderRadius: "24px",
    borderColor: "#eeeeee",
    // borderStyle: "dashed",
    // backgroundColor: "#fafafa",
    marginTop: 5,
    maxHeight: 140,
    maxWidth: 100,
    justifyContent: "center",
  },

  thumb: {
    display: "inline-flex",
    borderRadius: "24px",
    border: "1px solid #eaeaea",
    marginBottom: 4,
    marginRight: 2,
    width: "100%",
    height: 100,
    padding: 4,
    boxSizing: "border-box",
    justifyContent: "center",
    maxHeight: "85px",
    height: "85px",
    padding: 4,
    boxSizing: "border-box",
    justifyContent: "center",

    margin: "0 auto",
  },
  dropzoneStyle: {
    width: "100%",
    height: "auto",
    borderWidth: 2,
    borderColor: "rgb(102, 102, 102)",
    borderStyle: "dashed",
    borderRadius: 5,
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },

  img: {
    display: "block",
    width: "auto",
    height: "100%",
    borderRadius: "24px",
  },

  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: "24px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },

  acceptStyle: {
    borderColor: "#00e676",
  },

  rejectStyle: {
    borderColor: "#ff1744",
  },

  uploadBoxStyle: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  deleteIcon: {
    display: "flex",
    borderRadius: 50,
    maxWidth: 30,
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "absolute",
    right: -10,
    top: 0,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 15,
    color: "white",
    cursor: "pointer",
  },
  uploadBoxStyle2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "120px",
    maxHeight: "120px",
    maxWidth: "250px",
    position: "relative",
  },
  mockupStyle: {
    display: "flex",
    justifyContent: "center",
    maxHeight: "150px",
    minHeight: "150px",
    margin: "20px",
  },

  editButton: {
    backgroundColor: theme.palette.primary.secondary,
  },

  section: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerSection: {
    [theme.breakpoints.down("md")]: {
      mb: 20,
    },
    [theme.breakpoints.down("sm")]: {
      mb: 5,
    },
  },
  responsiveSection: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      margin: "0 auto",
    },
  },
  headerBackground: {
    position: "absolute",
    height: 500,
    width: 350,

    padding: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../img/overlay.png")})`,

    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("lg")]: {
      height: 500,
    },
    [theme.breakpoints.down("md")]: {
      height: 500,
    },
  },

  bePartBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../img/acadBackground.png")})`,
    minHeight: "100vh",
    backgroundSize: "cover",
    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "2em 0 2em 0",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: "6em 0 6em 0",
    },
  },
  bePartBackground2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../img/acadBackground.png")})`,
    minHeight: "35vh",
    backgroundSize: "cover",
    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "2em 0 2em 0",
    },
  },
  blueBg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../img/blueBackground.png")})`,
    minHeight: "75vh",
    backgroundSize: "cover",
    py: 10,
    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "2em 0 2em 0",
    },
  },
  headerBg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${require("../img/01-Fondo-azul-con-ciudad-para-Web.jpg")})`,
    minHeight: "75vh",
    backgroundSize: "cover",

    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "2em 0 2em 0",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  blueBg2: {
    backgroundImage: `url(${require("../img/blueBg2.png")})`,
    minHeight: "75vh",
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundColor: Colors.bgHome,
    // mixBlendMode: "multiply",
    pt: 0,
    // [theme.breakpoints.up("lg")]: {
    //     height: "40vh",
    //   },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: "2em 0 0em 0",
    },
  },

  footer: {
    backgroundColor: Colors.bgAcademy,
    color: theme.palette.common.white,
    padding: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
  },
  footerContainer: {
    justifyContent: "center",

    minHeight: "28vh",
  },

  carousel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "45vh",
    width: "100%",
  },

  footerSection: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "225px",
      margin: "2em auto",
    },
  },
  logoContainer: {
    width: "200px",
    height: "auto",
  },
  logo: {
    width: "100%",
    height: "auto",
  },

  titleGridContainer: {
    marginTop: 5,
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    marginRight: "auto",
    marginLeft: "auto",

    // maxWidth: '1330px',
  },
  titleCard: {
    // width:300,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    padding: "0.3rem 4rem 0.3rem 0.9rem",
    borderRadius: "4px",
    // clipPath: "polygon(0 0, 100% 0, 90% 96%, 0 100%)",
  },
  button: {
    width: 200,
    height: 35,
    backgroundColor: Colors.grey2, // Default button background color
    paddingHorizontal: 20, // Horizontal padding
    paddingVertical: 10, // Vertical padding
    borderRadius: "18px", // Border radius
  },

  //TERMS PRIVACY

  titleCard: {
    // width:300,
    backgroundColor: theme.palette.primary.main,
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    padding: "0.3rem 4rem 0.3rem 0.9rem",
    borderRadius: "4px",
    // clipPath: "polygon(0 0, 100% 0, 90% 96%, 0 100%)",
  },

  boldText: {
    fontWeight: "bold",
  },
  boldTextTerms: {
    fontWeight: "800 !important",
    fontSize: "1.3rem !important",

    color: theme.palette.primary.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem !important",
    },
  },
  boldTextLight: {
    fontWeight: "800 !important",
    fontSize: "1.3rem !important",
    color: "white",
    userSelect: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
};
