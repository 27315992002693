import React from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { MdArrowForward } from "react-icons/md";
import { styles } from "../../../assets/styles/styles";
import hero from "../../../assets/img/Sección-1-Ejecutivo.png";
import overlay from "../../../assets/img/overlay.png";
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import VgButton from "../general/VgButton";

export const Header = ({ theme }) => {
  const yellow = theme.palette.primary.secondary;
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <Box sx={styles.headerBg}>
      <Container maxWidth={"xl"} sx={styles.section}>
        <Grid
          container
          pt={10}
          sx={styles.headerSection}
          justifyContent={"space-between"}
        >
          <Grid item sm={4}>
            <Box sx={{ width: "100%", mb: 1, mt: 8 }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1" ml={0.5}>
                  VANGUARD
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ fontSize: 32, textTransform: "uppercase" }}
                >
                  Apaláncate de nuestro
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: 36,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  ecosistema digital
                </Typography>
              </Box>
            </Box>

            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                mb: 2,
              }}
            >
              <Typography variant="body1" sx={{ textTransform: "uppercase" }}>
                Vanguard cuenta con un portafolio de oportunidades para crecer
              </Typography>
            </Box>
            <VgButton
              title={"Registrarse"}
              bgColor={Colors.yellowAcademy}
              bgColorB={Colors.yellowDark}
              height={50}
              fontSize={24}
              width={200}
              onPress={() =>
                openInNewTab("https://academy.vanguardglobal.pro/auth/register")
              }
            />
          </Grid>
          <Grid item sm={6} md={6}>
            <Box
              component={"img"}
              src={hero}
              alt="Logo"
              sx={{
                height: "85vh",
                [theme.breakpoints.down("md")]: {
                  height: "50vh",
                  mt: 10,
                },
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
