import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Login from "./../views/auth/Login";
import SidebarLayout from "./../components/SidebarLayout";
import { PrivateRoute } from "./PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../firebase/firebase-config";
import {
  logoutUser,
  setIsLoadingAuth,
  signInWithEmail,
} from "../store/actions/auth";
import { CircularProgress, Typography } from "@mui/material";
import { HomeView } from "../views/HomeView";
import { PrivacyPolicy } from "../views/legal/PrivacyPolicy";
import { Terms } from "../views/legal/Terms";

function AppRoutes() {
  const dispatch = useDispatch();
  const history = useLocation();
  const { loggedIn, isLoadingAuth } = useSelector((state) => state.auth);
  const lastUrl = localStorage.getItem("lastUrl") || "/home";
  // useEffect(() => {
  //   dispatch(setIsLoadingAuth(true));
  //   auth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       const metadata = await user.getIdTokenResult();
  //       // Check if the user's email is allowed
  //       if (
  //         user.email === "dev@northtec.io" ||
  //         user.email === "keylinmp@gmail.com"
  //       ) {
  //         dispatch(signInWithEmail(user.toJSON(), metadata.claims));
  //         sessionStorage.setItem("user", JSON.stringify(user.toJSON()));
  //       } else {
  //         sessionStorage.removeItem("user");
  //         dispatch(logoutUser());
  //       }

  //       dispatch(setIsLoadingAuth(false));
  //     } else {
  //       console.log("no hay");
  //       sessionStorage.removeItem("user");
  //       dispatch(logoutUser());
  //       dispatch(setIsLoadingAuth(false));
  //     }
  //   });
  // }, [dispatch]);

  // if (
  //   metadata.claims.superadmin ||
  //   metadata.claims.admin ||
  //   metadata.claims.almighty
  // ) {
  //   dispatch(signInWithEmail(user.toJSON(), metadata.claims));
  //   sessionStorage.setItem("user", JSON.stringify(user.toJSON()));
  // } else {
  //   sessionStorage.removeItem("user");
  //   dispatch(logoutUser());
  // }

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.setItem("lastUrl", history.pathname);
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [history]);

  if (isLoadingAuth) {
    return (
      <div id="login">
        <div className="login-wrapper ">
          <div className="align">
            <CircularProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<SidebarLayout />}>
          <Route
            path=""
            element={
              true ? (
                <Navigate replace to="/home" />
              ) : (
                <Navigate to="/auth/login" />
              )
            }
          />
          {/* ERROR PAGE <Route path="*" element={<Dashboard />} /> */}
          <Route
            path="/*"
            element={
              <Routes>
                <Route index path="/home" element={<HomeView />} />
                <Route
                  index
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                />
                <Route index path="/terms" element={<Terms />} />
              </Routes>
            }
          />
        </Route>

        {!loggedIn ? (
          <Route Route path="/auth">
            <Route path="" element={<Navigate replace to="login" />} />
            <Route path="login" element={<Login />} />
          </Route>
        ) : null}
      </Routes>
    </>
  );
}

export default AppRoutes;
