import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TopNavbar from "./TopNavbar";
import { sidebarStyles } from "./../../assets/styles/sidebarStyles";
import MenuDropdownButton from "./menu/MenuDropdownButton";
import MenuButton from "./menu/MenuButton";
import {
  menu,
  menuAcademy,
  menuNotifications,
  menuOther,
  menuSchedules,
  menuSignals,
  menuUsers,
} from "./menu/menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Colors from "../../assets/styles/Colors";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { ExpandLess } from "@mui/icons-material";
import { MdCastForEducation, MdHome, MdPeople } from "react-icons/md";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  boxSizing: "border-box",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",

  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandLess sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: Colors.bgLight,
  flexDirection: "row",

  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SidebarDrawer({
  handleDrawerToggle,
  sidebarOpen,
  setOpen,
}) {
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openD, setOpenD] = useState({});

  function handleClick(id) {
    setOpenD((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }

  return (
    <>
      <TopNavbar
        drawerWidth={drawerWidth}
        handleDrawerOpen={handleDrawerOpen}
        setOpen={setOpen}
        open={sidebarOpen}
      />

      <Drawer
        variant={
          !isMobile
            ? sidebarOpen
              ? "permanent"
              : "permanent"
            : sidebarOpen
            ? "permanent"
            : "temporary"
        }
        open={sidebarOpen}
        sx={sidebarStyles.drawerStyles}
      >
        <DrawerHeader>
          <div className="mr-auto ml-auto">
            <img
              style={{ width: "32px", height: "32px", marginLeft: 20 }}
              src={require("../../assets/img/logos/logoWhite.png")}
              alt="onecore"
            />
          </div>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ color: "azure" }} />
        <Accordion sx={{ mt: 4 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              className="mr-auto ml-auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                mb: 1,
              }}
            >
              {sidebarOpen ? ( // Check if the sidebar is open
                <Typography
                  variant="h5"
                  sx={{ color: Colors.greyLight, fontSize: 18 }}
                >
                  Home
                </Typography>
              ) : (
                <MdHome size={24} /> // Render the icon when the sidebar is closed
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: 0, backgroundColor: Colors.bgLight }}
          >
            <List>
              {menu.map((item) => {
                return (
                  <div key={item.title}>
                    {item.submenu != null ? (
                      <MenuDropdownButton
                        open={sidebarOpen}
                        openD={openD}
                        handleClick={handleClick}
                        submenu={item.submenu}
                        item={item}
                      />
                    ) : (
                      <MenuButton open={sidebarOpen} item={item} />
                    )}
                  </div>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              className="mr-auto ml-auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                mb: 1,
              }}
            >
              {sidebarOpen ? ( // Check if the sidebar is open
                <Typography
                  variant="h5"
                  sx={{ color: Colors.greyLight, fontSize: 18 }}
                >
                  Academia
                </Typography>
              ) : (
                <MdCastForEducation size={24} /> // Render the icon when the sidebar is closed
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: 0, backgroundColor: Colors.bgLight }}
          >
            <List>
              {menuAcademy.map((item) => {
                return (
                  <div key={item.title}>
                    {item.submenu != null ? (
                      <MenuDropdownButton
                        open={sidebarOpen}
                        openD={openD}
                        handleClick={handleClick}
                        submenu={item.submenu}
                        item={item}
                      />
                    ) : (
                      <MenuButton open={sidebarOpen} item={item} />
                    )}
                  </div>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              className="mr-auto ml-auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                mb: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: Colors.greyLight, fontSize: 18 }}
              >
                Agendas
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: 0, backgroundColor: Colors.bgLight }}
          >
            <List>
              {menuSchedules.map((item) => {
                return (
                  <div key={item.title}>
                    {item.submenu != null ? (
                      <MenuDropdownButton
                        open={sidebarOpen}
                        openD={openD}
                        handleClick={handleClick}
                        submenu={item.submenu}
                        item={item}
                      />
                    ) : (
                      <MenuButton open={sidebarOpen} item={item} />
                    )}
                  </div>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              className="mr-auto ml-auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                mb: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: Colors.greyLight, fontSize: 18 }}
              >
                Señales
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: 0, backgroundColor: Colors.bgLight }}
          >
            <List>
              {menuSignals.map((item) => {
                return (
                  <div key={item.title}>
                    {item.submenu != null ? (
                      <MenuDropdownButton
                        open={sidebarOpen}
                        openD={openD}
                        handleClick={handleClick}
                        submenu={item.submenu}
                        item={item}
                      />
                    ) : (
                      <MenuButton open={sidebarOpen} item={item} />
                    )}
                  </div>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion disabled>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              className="mr-auto ml-auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                mb: 1,
              }}
            >
              {sidebarOpen ? ( // Check if the sidebar is open
                <Typography
                  variant="h5"
                  sx={{ color: Colors.greyLight, fontSize: 18 }}
                >
                  Usuarios
                </Typography>
              ) : (
                <MdPeople size={24} /> // Render the icon when the sidebar is closed
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: 0, backgroundColor: Colors.bgLight }}
          >
            <List>
              {menuUsers.map((item) => {
                return (
                  <div key={item.title}>
                    {item.submenu != null ? (
                      <MenuDropdownButton
                        open={sidebarOpen}
                        openD={openD}
                        handleClick={handleClick}
                        submenu={item.submenu}
                        item={item}
                      />
                    ) : (
                      <MenuButton open={sidebarOpen} item={item} />
                    )}
                  </div>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion disabled>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              className="mr-auto ml-auto"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                mb: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: Colors.greyLight, fontSize: 18 }}
              >
                Notificaciones
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: 0, backgroundColor: Colors.bgLight }}
          >
            <List>
              {menuNotifications.map((item) => {
                return (
                  <div key={item.title}>
                    {item.submenu != null ? (
                      <MenuDropdownButton
                        open={sidebarOpen}
                        openD={openD}
                        handleClick={handleClick}
                        submenu={item.submenu}
                        item={item}
                      />
                    ) : (
                      <MenuButton open={sidebarOpen} item={item} />
                    )}
                  </div>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion> */}
      </Drawer>
    </>
  );
}
