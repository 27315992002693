import Swal from "sweetalert2";
import { db } from "../../../firebase/firebase-config";
import { ADMIN_API_URL } from "../../variables/globals";
export const SET_SCHEDULE = "SET_SIGNALS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const GET_SCHEDULE_ONECORE = "GET_SCHEDULE_ONECORE";
export const GET_SCHEDULE_OPERATIVES = "GET_SCHEDULE_OPERATIVES";
export const GET_SCHEDULE_EDUCATIVES = "GET_SCHEDULE_EDUCATIVES";
export const GET_MASTERCLASS = "GET_MASTERCLASS";
export const GET_SESSIONS = "GET_SESSIONS";
// export const getSchedule = async () => {
//   const querySnapshot = await db()
//     .collection("schedules")
//     .doc("ttKRoUGCbIdXkd8iPyDd")
//     .collection("events")
//     .get();

//   if (querySnapshot.empty) {
//     return [];
//   }

//   return querySnapshot.docs.map((doc) => {
//     return { id: doc.id, ...doc.data() };
//   });
// };

export const getAllSchedules = () => {
  return (dispatch) => {
    const schedules = [];
    dispatch(isLoading(true));
    db.collection("schedules")
      .doc("ttKRoUGCbIdXkd8iPyDd")
      .collection("events")
      .get()
      .then(async (itemsFromFs) => {
        console.log("success");
        itemsFromFs.docs.forEach((item) =>
          schedules.push({ id: item.id, ...item.data() })
        );
        dispatch(getSchedule(schedules));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getMasterClassFB = () => {
  return (dispatch) => {
    const masterclassItems = [];
    dispatch(isLoading(true));
    db.collection("businesses")
      .doc("root")
      .collection("schedules")
      .doc("masterclass")
      .collection("events")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          masterclassItems.push({ id: item.id, ...item.data() })
        );
        console.log(masterclassItems, "success");
        dispatch(getMasterclass(masterclassItems));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getSessionsFB = () => {
  return (dispatch) => {
    const sessionItems = [];
    dispatch(isLoading(true));
    db.collection("businesses")
      .doc("root")
      .collection("schedules")
      .doc("sessions")
      .collection("events")
      .get()
      .then(async (itemsFromFs) => {
        itemsFromFs.docs.forEach((item) =>
          sessionItems.push({ id: item.id, ...item.data() })
        );
        console.log(sessionItems, "success");
        dispatch(getSessions(sessionItems));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getScheduleFB = (content, category, day) => {
  return db
    .collection("businesses")
    .doc(content)
    .collection("schedule")
    .doc(category)
    .collection("days")
    .doc(day)
    .collection("items");
};
export const setScheduleOperatives = (lang) => {
  return async (dispatch) => {
    let items = [];
    try {
      for (let index = 1; index < 8; index++) {
        const docsSnapShot = await getScheduleFB(
          "root",
          "operative",
          `${index}`
        )
          .orderBy("hour", "asc")
          .get();

        let schedule = [];
        docsSnapShot.docs.forEach((item) => {
          schedule.push({ id: item.id, data: item.data() });
        });
        console.log("schedule:", schedule);
        items.push({
          id: `${index}`,
          items: schedule,
        });
      }

      dispatch({
        type: GET_SCHEDULE_OPERATIVES,
        operatives: items,
      });
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data." + err);
    }
  };
};

export const setScheduleEducatives = (lang) => {
  return async (dispatch) => {
    let items = [];
    try {
      for (let index = 1; index < 8; index++) {
        const docsSnapShot = await getScheduleFB(
          "root",
          "educative",
          `${index}`
        )
          .orderBy("hour", "asc")
          .get();

        let schedule = [];
        docsSnapShot.docs.forEach((item) => {
          schedule.push({ id: item.id, data: item.data() });
        });
        // console.log('lang:', lang);
        // console.log('category:', 'educative');
        // console.log('day:', `${index}`);
        items.push({
          id: `${index}`,
          items: schedule,
        });
      }

      dispatch({
        type: GET_SCHEDULE_EDUCATIVES,
        educatives: items,
      });
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data." + err);
    }
  };
};
export const uploadEvent = async (values, scheduleName) => {
  try {
    await db
      .collection("businesses")
      .doc("root")
      .collection("schedules")
      .doc(scheduleName)
      .collection("events")
      .add(values);

    console.log("Event uploaded successfully");
  } catch (error) {
    console.error("Error uploading event", error);
    return null;
  }
};
export const editEvent = async (eventId, values, scheduleName) => {
  try {
    await db
      .collection("businesses")
      .doc("root")
      .collection("schedules")
      .doc(scheduleName)
      .collection("events")
      .doc(eventId)
      .set(values, { merge: true });

    console.log("Event updated successfully");
  } catch (error) {
    console.error("Error updating event", error);
    return null;
  }
};

export const deleteSchedule = (itemId) => {
  return (dispatch) => {
    try {
      db.collection("schedules")
        .doc("ttKRoUGCbIdXkd8iPyDd")
        .collection("events")
        .doc(itemId)
        .delete();
    } catch (error) {
      console.log(error);
    }
  };
};
export const callDeleteAlertSchedule = (
  elementsToDelete,
  deleteElementFirebase,
  handleRefresh,
  successMsg
  //deleteElement
) => {
  Swal.fire({
    title: "Alerta",
    text: "¿Esta seguro que desea eliminar?",
    icon: "warning",
    confirmButtonText: "Aceptar",
    showDenyButton: true,
    denyButtonText: "Volver",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteElementFirebase(elementsToDelete);
      //deleteElement()
      Swal.fire(successMsg, "", "success");
      if (handleRefresh) {
        handleRefresh();
      }
    }
  });
};

export const getSchedule = (schedules) => ({
  type: SET_SCHEDULE,
  payload: {
    schedules,
  },
});
export const getMasterclass = (masterclass) => ({
  type: GET_MASTERCLASS,
  payload: {
    masterclass,
  },
});
export const getSessions = (sessions) => ({
  type: GET_SESSIONS,
  payload: {
    sessions,
  },
});
export const isLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: {
    isLoading,
  },
});
