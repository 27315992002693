import React from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Header } from "../components/home/Header";
import { Community } from "../components/home/Community";
import { styles } from "../../assets/styles/styles";
import { HomeVideo } from "../components/home/HomeVideo";
import { About } from "../components/home/About";
import { AcademyPromo } from "../components/home/AcademyPromo";
import { Team } from "../components/home/Team";
import { Packages } from "../components/home/Packages";
import VanguardFooter from "../components/shared/VanguardFooter";
import { VanguardNavbar } from "../components/shared/VanguardNavbar";

export const HomeView = () => {
  const theme = useTheme();
  return (
    <div>
      <VanguardNavbar />
      <Header theme={theme} />
      <Box sx={styles.blueBg2}>
        <HomeVideo />

        <Community />
        <About />
      </Box>
      <AcademyPromo theme={theme} />
      <Team theme={theme} />
      <Box sx={styles.bePartBackground2}>
        <Grid container>
          <Grid item md={12} container justifyContent={"center"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                [theme.breakpoints.down("sm")]: {
                  width: "90%",
                  margin: "0 auto",
                },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "black",
                  textTransform: "uppercase",
                  fontWeight: "800",
                  mb: 2,
                  [theme.breakpoints.down("sm")]: {
                    textAlign: "center",
                    fontSize: 18,
                    mb: 5,
                  },
                }}
              >
                Conviertete en un experto
              </Typography>

              <Typography
                variant={"body1"}
                sx={{
                  color: "black",
                  width: "60%",
                  textAlign: "center",
                  fontSize: 16,
                  textTransform: "uppercase",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%",
                    textAlign: "center",
                    fontSize: 14,
                  },
                }}
              >
                al lado de Vanguard y su equipo de profesionales. Te ayudaremos
                e incluso nos encargaremos de pasar tus pruebas de fondeo y que
                cumplas con tus expectativas
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Packages theme={theme} />
      <VanguardFooter />
    </div>
  );
};
