import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Link,
  IconButton,
  TextField,
} from "@mui/material";

import {
  FaYoutube,
  FaTwitch,
  FaInstagram,
  FaTiktok,
  FaFacebook,
  FaTelegramPlane,
} from "react-icons/fa";
import logo from "../../../assets/img/logos/logoWhite.png";
import { styles } from "../../../assets/styles/styles";
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";

const VanguardFooter = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };
  const handleWhatsAppClick = () => {
    const phoneNumber = "50686187854";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleEmail = () => {
    const phoneNumber = "+506 8618 7854";
    const message = `Correo Electrónico: ${email}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <footer>
      <Box sx={styles.footer}>
        <Container maxWidth="xxl">
          <Grid container spacing={2} sx={styles.footerContainer}>
            {/* First column content */}
            <Grid item xs={12} sm={6} md={3} sx={styles.footerSection}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={styles.logoContainer}>
                  {/* Combine logo and social icons */}
                  <img src={logo} alt="Logo" style={styles.logo} />
                </Box>

                <Box sx={{ display: "flex", gap: "10px" }}>
                  {/* Social icons in a row */}
                  <IconButton
                    href="https://www.youtube.com/@vanguardglobalpro"
                    target="_blank"
                  >
                    <FaYoutube />
                  </IconButton>

                  <IconButton
                    href="https://www.instagram.com/vanguard_businessgroup/"
                    target="_blank"
                  >
                    <FaInstagram />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Enlaces Útiles</Typography>
              <List>
                {/* <ListItem sx={{ paddingLeft: 0, cursor: "pointer" }}>
                  <Link onClick={() => navigateTo("/faq)")}>
                    Preguntas frecuentes
                  </Link>
                </ListItem> */}
                <ListItem sx={{ paddingLeft: 0, cursor: "pointer" }}>
                  <Link onClick={() => navigateTo("/terms")}>
                    Términos y condiciones
                  </Link>
                </ListItem>
                <ListItem sx={{ paddingLeft: 0, cursor: "pointer" }}>
                  <Link onClick={() => navigateTo("/privacy-policy")}>
                    Política de privacidad
                  </Link>
                </ListItem>
              </List>
            </Grid>
            {/* Second column content */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Soporte </Typography>
              <List>
                {/* <ListItem>
                  <Link onClick={() => navigateTo("/about-us))}>
                    Sobre nosotros
                  </Link>
                </ListItem> */}
                <ListItem sx={{ paddingLeft: 0, cursor: "pointer" }}>
                  <Link onClick={handleWhatsAppClick}>Soporte</Link>
                </ListItem>
                {/* <ListItem>
                  <Link href="#">Equipo</Link>
                </ListItem> */}
              </List>
            </Grid>

            {/* Third column content */}

            {/* Fourth column content */}
            {/* <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">Contacto</Typography>
              <List>
                <ListItem sx={{ paddingLeft: 0 }}>
                  <Link href="#">Contáctanos</Link>
                </ListItem>
                <ListItem sx={{ paddingLeft: 0 }}>
                  <Link href="#">Soporte</Link>
                </ListItem>

              </List>
            </Grid> */}
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: "flex",
          background: Colors.bgAcademy,
          height: 50,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>
          Copyright 2023 Vanguard. Todos los derechos reservados.
        </Typography>
      </Box>
    </footer>
  );
};

export default VanguardFooter;
