import { Box, Container, Grid, useTheme } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";

export const HomeVideo = () => {
  const theme = useTheme();
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        minHeight: "100vh",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pt: 15,
        pb: 15,
        [theme.breakpoints.down("md")]: {
          minHeight: "75vh",
        },
      }}
    >
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ width: "100%", borderRadius: "18px" }}
      >
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url="https://www.youtube.com/watch?v=ZnX3ki9AEUQ"
            controls
            muted
            width="100%"
            height="100%"
          />
        </div>
      </Grid>
    </Container>
  );
};
