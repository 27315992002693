import Swal from "sweetalert2";
import { db } from "../../../firebase/firebase-config";
import { types } from "../types/types";
import { ADMIN_API_URL } from "../../variables/globals";
export const SET_ITEMS = "SET_ITEMS";
export const SET_COURSES = "SET_COURSES";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const GET_EVENTS = "GET_EVENTS";
export const GET_RECOMMENDED = "GET_RECOMMENDED";
export const GET_SUCCESS = "GET_SUCCESS";
export const GET_SERVICES = "GET_SERVICES";
export const GET_ACADEMY_NEWS = "GET_ACADEMY_NEWS";
export const GET_TRADING_NEWS = "GET_TRADING_NEWS";
export const GET_MARKETING_NEWS = "GET_MARKETING_NEWS";
export const GET_ONEMINDSET = "GET_ONEMINDSET";
export const GET_WOMANOFPOWER = "GET_WOMANOFPOWER";
export const GET_PERSONALDEV = "GET_PERSONALDEV";
export const GET_MODULEFOUR = "GET_MODULEFOUR";
export const GET_FOREX = "GET_FOREX";
export const GET_BINARIES = "GET_BINARIES";
export const GET_CRYPTOS = "GET_CRYPTOS";
export const GET_SUPPORT_PHONE = "GET_SUPPORT_PHONE";
export const GET_CATEGORY_ITEMS = "GET_CATEGORY_ITEMS";
export const GET_SYNTHETICS = "GET_SYNTHETICS";
export const getItemsFB = (content) => {
  // return firestore().collection('businesses').doc(content).collection('items');
  return db.collection("businesses").doc("root").collection("items");
};

export const getAllItems = () => {
  return (dispatch) => {
    const items = [];
    dispatch(isLoading(true));
    db.collection("items")
      .get()
      .then(async (itemsFromFs) => {
        console.log("GetItems Once");
        itemsFromFs.docs.forEach((item) =>
          items.push({ id: item.id, ...item.data() })
        );
        dispatch(getItems(items));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAcademyItem = async (id) => {
  const item = await db
    .collection("businesses")
    .doc("root")
    .collection("items")
    .doc(id)
    .get();

  if (item.exists) {
    const dataWithId = { id: item.id, ...item.data() };
    return dataWithId;
  } else {
    return null;
  }
};

export const setRecommended = (lang) => {
  return async (dispatch) => {
    let items = [];
    try {
      const docsSnapshot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "recommended")
        .orderBy("orderNumber", "asc")
        .get();

      docsSnapshot.docs.forEach((doc) => {
        items.push({
          id: doc.id,
          tags: doc.data().tags,
          name: doc.data().name,
          imageURL: doc.data().imageURL || "",
          cdate: doc.data().cdate,
          description: doc.data().description,
          videoURL: doc.data().videoURL || "",
          geopoint: doc.data().geopoint || "",
          streamURL: doc.data().streamURL || "",
          category: doc.data().category || "",
          startDate: doc.data().startDate || "",
          finishDate: doc.data().finishDate || "",
          clickMe: doc.data().clickMe || "",
          section: doc.data().section || "",
          thumbnail: doc.data().thumbnail || "",
          isGoldPlan: doc.data().isGoldPlan || false,
          isMembersOnly: doc.data().isMembersOnly || false,
          orderNumber: doc.data().orderNumber || 0,
        });
      });

      dispatch({
        type: GET_RECOMMENDED,
        recommended: items,
      });
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data." + err);
    }
  };
};
export const setEvents = (lang) => {
  return async (dispatch) => {
    let items = [];
    try {
      const docsSnapshot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("category", "==", "events")
        .orderBy("orderNumber", "asc")
        .get();

      docsSnapshot.docs.forEach((doc) => {
        items.push({
          id: doc.id,
          tags: doc.data().tags,
          name: doc.data().name,
          imageURL: doc.data().imageURL || "",
          cdate: doc.data().cdate,
          description: doc.data().description,
          videoURL: doc.data().videoURL || "",
          geopoint: doc.data().geopoint || "",
          streamURL: doc.data().streamURL || "",
          category: doc.data().category || "",
          startDate: doc.data().startDate || "",
          finishDate: doc.data().finishDate || "",
          clickMe: doc.data().clickMe || "",
          section: doc.data().section || "",
          thumbnail: doc.data().thumbnail || "",
          isGoldPlan: doc.data().isGoldPlan || false,
          isMembersOnly: doc.data().isMembersOnly || false,
          orderNumber: doc.data().orderNumber || 0,
        });
      });

      dispatch({
        type: GET_EVENTS,
        events: items,
      });
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data." + err);
    }
  };
};

export const setCategory = (lang, category, subcat, level) => {
  return async (dispatch) => {
    console.log(lang, category, subcat);
    let items = [];
    let docsSnapShot;
    try {
      if (subcat && level) {
        docsSnapShot = await getItemsFB(lang === "es" ? "root" : lang)
          .where("tags", "array-contains", category)
          .where("section", "==", subcat)
          .where("level", "==", level)
          .orderBy("orderNumber", "asc")
          .get();
      } else if (subcat) {
        docsSnapShot = await getItemsFB(lang === "es" ? "root" : lang)
          .where("tags", "array-contains", category)
          .where("section", "==", subcat)
          .orderBy("orderNumber", "asc")
          .get();
      } else {
        docsSnapShot = await getItemsFB(lang === "es" ? "root" : lang)
          .where("tags", "array-contains", category)
          .orderBy("orderNumber", "asc")
          .get();
      }

      docsSnapShot.docs.forEach((doc) => {
        const data = doc.data();
        items.push({
          id: doc.id,
          tags: data.tags,
          name: data.name,
          imageURL: data.imageURL || "",
          cdate: data.cdate,
          description: data.description,
          videoURL: data.videoURL || "",
          geopoint: data.geopoint || "",
          streamURL: data.streamURL || "",
          category: data.category || "",
          startDate: data.startDate || "",
          finishDate: data.finishDate || "",
          clickMe: data.clickMe || "",
          section: data.section || "",
          thumbnail: data.thumbnail || "",
          isGoldPlan: data.isGoldPlan || false,
          isMembersOnly: data.isMembersOnly || false,
          type: data.type || "",
          level: data.level || "",
        });
      });

      dispatch({
        type: GET_CATEGORY_ITEMS,
        categoryItems: items,
      });
      // dispatchCategory(dispatch, category, items);
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data." + err);
    }
  };
};
export const setCommunity = (lang) => {
  return async (dispatch) => {
    try {
      const itemsWomanOfPower = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "education:womanofpower")
        .orderBy("cdate", "asc")
        .get();

      const itemsPersonalDevelopment = await getItemsFB(
        lang === "es" ? "root" : lang
      )
        .where("tags", "array-contains", "education:personaldevelopment")
        .orderBy("cdate", "asc")
        .get();

      const itemsOneMindset = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "education:onemindset")
        .orderBy("cdate", "asc")
        .get();

      const items = {
        womanOfPower: itemsWomanOfPower.docs.map((doc) => ({
          id: doc.id, // Add the id property to the document data
          ...doc.data(),
        })),
        personalDevelopment: itemsPersonalDevelopment.docs.map((doc) => ({
          id: doc.id, // Add the id property to the document data
          ...doc.data(),
        })),
        oneMindset: itemsOneMindset.docs.map((doc) => ({
          id: doc.id, // Add the id property to the document data
          ...doc.data(),
        })),
      };

      dispatch({
        type: GET_WOMANOFPOWER,
        womanOfPower: items.womanOfPower,
      });

      dispatch({
        type: GET_PERSONALDEV,
        personalDev: items.personalDevelopment,
      });

      dispatch({
        type: GET_ONEMINDSET,
        onemindset: items.oneMindset,
      });
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data: " + err);
    }
  };
};

export const setSynthetics = (lang) => {
  return async (dispatch) => {
    let synthetics = {
      pro: [],
      lab: [],
    };

    try {
      const docsSnapshot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "trading:synthetics")
        .orderBy("orderNumber", "asc")
        .get();

      if (!docsSnapshot.empty) {
        docsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const item = {
            id: doc.id,
            tags: data.tags,
            name: data.name,
            imageURL: data.imageURL || "",
            cdate: data.cdate,
            description: data.description,
            videoURL: data.videoURL || "",
            geopoint: data.geopoint || "",
            streamURL: data.streamURL || "",
            category: data.category || "",
            startDate: data.startDate || "",
            finishDate: data.finishDate || "",
            clickMe: data.clickMe || "",
            thumbnail: data.thumbnail || "",
            isGoldPlan: data.isGoldPlan || false,
            isMembersOnly: data.isMembersOnly || false,
            type: data.type || "",
            section: data.section || "",
            level: data.level || "",
            orderNumber: data.orderNumber || 0,
          };

          if (item.section) {
            synthetics[item.section].push(item);
          }
        });
      } else {
        console.error("No documents found in the query.");
      }

      dispatch({
        type: GET_SYNTHETICS,
        synthetics,
      });
    } catch (err) {
      console.error("Error in setSynthetics:", err);
      throw new Error("Error getting data: " + err);
    }
  };
};

export const setForex = (lang) => {
  return async (dispatch) => {
    let forex = {
      beginner: [],
      intermediate: [],
      advanced: [],
    };

    try {
      const docsSnapshot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "trading:forex")
        .orderBy("orderNumber", "asc")
        .get();

      if (!docsSnapshot.empty) {
        docsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const item = {
            id: doc.id,
            tags: data.tags,
            name: data.name,
            imageURL: data.imageURL || "",
            cdate: data.cdate,
            description: data.description,
            videoURL: data.videoURL || "",
            geopoint: data.geopoint || "",
            streamURL: data.streamURL || "",
            category: data.category || "",
            startDate: data.startDate || "",
            finishDate: data.finishDate || "",
            clickMe: data.clickMe || "",
            thumbnail: data.thumbnail || "",
            isGoldPlan: data.isGoldPlan || false,
            isMembersOnly: data.isMembersOnly || false,
            type: data.type || "",
            section: data.section || "",
            orderNumber: data.orderNumber || 0,
          };

          if (item.section) {
            forex[item.section].push(item);
          }
        });
      } else {
        console.error("No documents found in the query.");
      }

      dispatch({
        type: GET_FOREX,
        forex,
      });
    } catch (err) {
      console.error("Error getting forex:", err);
      throw new Error("Error getting data: " + err);
    }
  };
};
export const setCryptos = (lang) => {
  return async (dispatch) => {
    let cryptos = {
      beginner: [],
      intermediate: [],
      advanced: [],
    };

    try {
      const docsSnapshot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "trading:cryptos")
        .orderBy("orderNumber", "asc")
        .get();

      if (!docsSnapshot.empty) {
        docsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const item = {
            id: doc.id,
            tags: data.tags,
            name: data.name,
            imageURL: data.imageURL || "",
            cdate: data.cdate,
            description: data.description,
            videoURL: data.videoURL || "",
            geopoint: data.geopoint || "",
            streamURL: data.streamURL || "",
            category: data.category || "",
            startDate: data.startDate || "",
            finishDate: data.finishDate || "",
            clickMe: data.clickMe || "",
            thumbnail: data.thumbnail || "",
            isGoldPlan: data.isGoldPlan || false,
            isMembersOnly: data.isMembersOnly || false,
            type: data.type || "",
            section: data.section || "",
            orderNumber: data.orderNumber || 0,
          };

          if (item.section) {
            cryptos[item.section].push(item);
          }
        });
      } else {
        console.error("No documents found in the query.");
      }

      dispatch({
        type: GET_CRYPTOS,
        cryptos,
      });
    } catch (err) {
      console.error("Error  getting  cryptos:", err);
      throw new Error("Error getting data: " + err);
    }
  };
};

export const setAcademy = (lang) => {
  return async (dispatch) => {
    let items = [];
    try {
      const docsSnapshot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "education:news")
        .orderBy("orderNumber", "asc")
        .get();

      docsSnapshot.docs.forEach((doc) => {
        items.push({
          id: doc.id,
          tags: doc.data().tags,
          name: doc.data().name,
          imageURL: doc.data().imageURL || "",
          cdate: doc.data().cdate,
          description: doc.data().description,
          videoURL: doc.data().videoURL || "",
          geopoint: doc.data().geopoint || "",
          streamURL: doc.data().streamURL || "",
          category: doc.data().category || "",
          startDate: doc.data().startDate || "",
          finishDate: doc.data().finishDate || "",
          clickMe: doc.data().clickMe || "",
          section: doc.data().section || "",
          thumbnail: doc.data().thumbnail || "",
          isGoldPlan: doc.data().isGoldPlan || false,
          isMembersOnly: doc.data().isMembersOnly || false,
          orderNumber: doc.data().orderNumber || 0,
        });
      });
      console.log(items);
      dispatch({
        type: GET_ACADEMY_NEWS,
        academyNews: items,
      });
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data." + err);
    }
  };
};
export const setTrading = (lang) => {
  return async (dispatch) => {
    let items = [];
    try {
      const docsSnapshot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "trading:news")
        .orderBy("orderNumber", "asc")
        .get();

      docsSnapshot.docs.forEach((doc) => {
        items.push({
          id: doc.id,
          tags: doc.data().tags,
          name: doc.data().name,
          imageURL: doc.data().imageURL || "",
          cdate: doc.data().cdate,
          description: doc.data().description,
          videoURL: doc.data().videoURL || "",
          geopoint: doc.data().geopoint || "",
          streamURL: doc.data().streamURL || "",
          category: doc.data().category || "",
          startDate: doc.data().startDate || "",
          finishDate: doc.data().finishDate || "",
          clickMe: doc.data().clickMe || "",
          section: doc.data().section || "",
          thumbnail: doc.data().thumbnail || "",
          isGoldPlan: doc.data().isGoldPlan || false,
          isMembersOnly: doc.data().isMembersOnly || false,
          orderNumber: doc.data().orderNumber || 0,
        });
      });

      dispatch({
        type: GET_TRADING_NEWS,
        tradingNews: items,
      });
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data." + err);
    }
  };
};

export const setSuccess = (lang) => {
  return async (dispatch) => {
    let items = [];
    try {
      const docsSnapShot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "success")
        .orderBy("cdate", "desc")
        .get();

      docsSnapShot.docs.forEach((doc) => {
        items.push({
          id: doc.id,
          tags: doc.data().tags,
          name: doc.data().name,
          imageURL: doc.data().imageURL || "",
          cdate: doc.data().cdate,
          description: doc.data().description,
          section: doc.data().section || "",
          isGoldPlan: doc.data().isGoldPlan || false,
          isMembersOnly: doc.data().isMembersOnly || false,
          orderNumber: doc.data().orderNumber || 0,
        });
      });
      dispatch({
        type: GET_SUCCESS,
        success: items,
      });
    } catch (err) {
      console.log(err);
      throw new Error("Error getting data." + err);
    }
  };
};

export const setServices = (lang) => {
  return async (dispatch) => {
    let services = {
      trading: [],
      academy: [],
      market: [],
      ai: [],
    };

    try {
      const docsSnapshot = await getItemsFB(lang === "es" ? "root" : lang)
        .where("tags", "array-contains", "services")
        .orderBy("cdate", "asc")
        .get();

      if (!docsSnapshot.empty) {
        docsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const item = {
            id: doc.id,
            tags: data.tags,
            name: data.name,
            cdate: data.cdate,
            description: data.description,
            videoURL: data.videoURL || "",
            streamURL: data.streamURL || "",
            category: data.category || "",
            isGoldPlan: data.isGoldPlan || false,
            isMembersOnly: data.isMembersOnly || false,
            section: data.section || "",
            orderNumber: doc.data().orderNumber || 0,
          };

          if (item.section) {
            services[item.section].push(item);
          }
        });
      } else {
        console.error("No documents found in the query.");
      }

      dispatch({
        type: GET_SERVICES,
        services,
      });
    } catch (err) {
      console.error("Error:", err);
      throw new Error("Error getting data: " + err);
    }
  };
};

export const getAllLiveEvents = () => {
  return (dispatch) => {
    const items = [];
    dispatch(isLoading(true));
    db.collection("items")
      .get()
      .then(async (itemsFromFs) => {
        console.log("GetItems Once");
        itemsFromFs.docs.forEach((item) =>
          items.push({ id: item.id, ...item.data() })
        );
        dispatch(getItems(items));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};
export const getAllCourses = () => {
  return async (dispatch) => {
    const items = [];

    await db
      .collection("courses")
      .get()
      .then(async (itemsFromFs) => {
        // console.log("GetItems Once");
        itemsFromFs.docs.forEach((item) =>
          items.push({ id: item.id, ...item.data() })
        );
        dispatch(setCourses(items));
        dispatch(isLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(isLoading(false));
      });
  };
};

export const getCourse = async (courseId) => {
  try {
    const courseRef = db.collection("courses").doc(courseId);
    const courseSnapshot = await courseRef.get();

    if (courseSnapshot.exists) {
      const courseData = courseSnapshot.data();

      // Fetch the videos subcollection
      const videosSnapshot = await courseRef.collection("videos").get();
      const videos = videosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return { courseData, videos };
    } else {
      throw new Error("Course not found");
    }
  } catch (error) {
    console.error("Error fetching course data:", error);
    throw error;
  }
};

export const uploadVideo = async (courseId, video, videoId = null) => {
  try {
    let videoRef;

    if (videoId) {
      videoRef = await db
        .collection("courses")
        .doc(courseId)
        .collection("videos")
        .doc(videoId)
        .update({
          name: video.name,
          videoURL: `${video.videoURL + "&"}`,
          category: video.category,
          duration: video.duration,
          tags: video.tags.split(",").map((tag) => tag.trim()),
        });
    } else {
      videoRef = await db
        .collection("courses")
        .doc(courseId)
        .collection("videos")
        .add({
          name: video.name,
          videoURL: `${video.videoURL + "&"}`,
          category: video.category,
          cdate: new Date(),
          duration: video.duration,
          isGoldPlan: false,
          isMembersOnly: false,
          tags: video.tags.split(",").map((tag) => tag.trim()),
          usersCount: 0,
        });
    }

    console.log("Video uploaded/updated successfully!");
    return videoRef.id; // Return the video ID
  } catch (error) {
    console.error("Error uploading/updating video:", error);
    return null;
  }
};

// SCHEDULES

export const uploadScheduleEvent = async (values, scheduleName) => {
  try {
    // Find the schedule document with name: "main"
    const scheduleRef = await db
      .collection("schedules")
      .where("name", "==", scheduleName)
      .get();

    if (!scheduleRef.empty) {
      // Get the ID of the first matching document
      const scheduleId = scheduleRef.docs[0].id;

      // Add the values to a new document within the "events" subcollection
      await db
        .collection("schedules")
        .doc(scheduleId)
        .collection("events")
        .add(values);

      console.log("Event uploaded successfully");
    } else {
      console.log("No schedule document found with name: 'main'");
    }
  } catch (error) {
    console.error("Error uploading event", error);
    return null;
  }
};

export const editScheduleEvent = async (eventId, values, scheduleName) => {
  try {
    const scheduleRef = await db
      .collection("schedules")
      .where("name", "==", scheduleName)
      .get();

    if (!scheduleRef.empty) {
      await db
        .collection("businesses")
        .doc("root")
        .collection("schedules")
        .doc(scheduleName)
        .collection("events")
        .doc(eventId)
        .set(values, { merge: true });

      console.log("Event updated successfully");
    } else {
      console.log("No schedule document found with name: 'main'");
    }
  } catch (error) {
    console.error("Error updating event", error);
    return null;
  }
};

// export const uploadCourse = async (courseData) => {
//   try {
//     const courseRef = await db.collection("courses").add({
//       name: courseData.name,
//       description: courseData.description,
//       imageURL: courseData.imageURL,
//       cdate: new Date(),
//     });

//     const courseId = courseRef.id;

//     for (const video of courseData.videos) {
//       await db
//         .collection("courses")
//         .doc(courseId)
//         .collection("videos")
//         .add({
//           name: video.name,
//           videoURL: video.videoURL,
//           category: video.category,
//           cdate: new Date(),
//           courseDuration: video.courseDuration,
//           isGoldPlan: video.isGoldPlan,
//           isMembersOnly: video.isMembersOnly,
//           tags: video.tags.split(",").map((tag) => tag.trim()), // Convert comma-separated tags to an array
//           usersCount: video.usersCount,
//         });
//     }

//     console.log("Course uploaded successfully!");
//   } catch (error) {
//     console.error("Error uploading course:", error);
//   }
// };

export const uploadCourse = async (courseData) => {
  try {
    const courseRef = await db.collection("courses").add({
      name: courseData.name,
      description: courseData.description,
      imageURL: courseData.imageURL,
      cdate: new Date(),
      isGoldPlan: courseData.isGoldPlan,
      isMembersOnly: courseData.isMembersOnly,
      isActive: true,
    });

    console.log("Course uploaded successfully!");
    return courseRef.id; // Return the course ID
  } catch (error) {
    console.error("Error uploading course:", error);
    return null;
  }
};
export const editCourse = async (courseData) => {
  try {
    await db.collection("courses").doc(courseData.id).set(
      {
        name: courseData.name,
        description: courseData.description,
        imageURL: courseData.imageURL,
        isGoldPlan: courseData.isGoldPlan,
        isMembersOnly: courseData.isMembersOnly,
        isActive: courseData.isActive,
      },
      { merge: true }
    );

    console.log("Course updated successfully!");
  } catch (error) {
    console.error("Error updating course:", error);
    return null;
  }
};

export const uploadCourseVideos = async (values, courseId) => {
  try {
    for (const video of values.videos) {
      await db
        .collection("courses")
        .doc(courseId)
        .collection("videos")
        .add({
          name: video.name,
          videoURL: video.videoURL,
          category: video.category,
          cdate: new Date(),
          duration: video.courseDuration,
          isGoldPlan: video.isGoldPlan,
          isMembersOnly: video.isMembersOnly,
          tags: video.tags.split(",").map((tag) => tag.trim()),
          usersCount: video.usersCount,
        });
    }

    console.log("Videos uploaded successfully!");
  } catch (error) {
    console.error("Error uploading videos:", error);
  }
};

export const uploadFlyer = async (values, tag) => {
  try {
    await db.collection("items").add({
      name: values.name,
      description: values.description,
      imageURL: values.imageURL,
      category: "events",
      details: values.details,
      streamURL: values.streamURL,
      cdate: new Date(),
      tags: ["home"],
    });

    console.log("Home Flyer uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};
export const uploadHomeItems = async (data) => {
  try {
    await db
      .collection("businesses")
      .doc("root")
      .collection("items")
      .add({
        ...data,

        cdate: new Date(),
      });

    console.log("Home Flyer uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};
export const uploadSynthetics = async (data, tag) => {
  try {
    await db
      .collection("businesses")
      .doc("root")
      .collection("items")
      .add({
        ...data,
        tags: ["trading:synthetics"],
      });

    console.log("`Synthetics`  uploaded successfully!");
  } catch (error) {
    console.error("Error uploading synthetics :", error);
  }
};
export const editSynthetics = async (data, id) => {
  try {
    // Reference the document to be updated
    const itemRef = db
      .collection("businesses")
      .doc("root")
      .collection("items")
      .doc(id);

    // Update the document with the new data (with merge set to true)
    await itemRef.set(
      {
        ...data,
        tags: ["trading:synthetics"], // You may want to update tags as well
      },
      { merge: true }
    );

    console.log("`Synthetics` updated successfully!");
  } catch (error) {
    console.error("Error updating synthetics:", error);
  }
};

export const uploadTrading = async (data, tag) => {
  try {
    await db
      .collection("businesses")
      .doc("root")
      .collection("items")
      .add({
        ...data,
        tags: [tag],
        cdate: new Date(),
      });

    console.log("`uploaded successfully!");
  } catch (error) {
    console.error("Error uploading :", error);
  }
};

export const editTraining = async (data, tag, id) => {
  try {
    // Reference the document to be updated using the specified tag
    const itemRef = db
      .collection("businesses")
      .doc("root")
      .collection("items")
      .doc(id);

    // Update the document with the new data (with merge set to true)
    await itemRef.set(
      {
        ...data,
        tags: [tag], // Update tags if needed
      },
      { merge: true }
    );

    console.log("Training updated successfully!");
  } catch (error) {
    console.error("Error updating training:", error);
  }
};

export const editFlyer = async (values, tag) => {
  try {
    await db
      .collection("items")
      .doc(values.id)
      .set(
        {
          name: values.name,
          description: values.description,
          imageURL: values.imageURL,
          category: "events",
          details: values.details,
          streamURL: values.streamURL,
          cdate: new Date(),
          tags: ["home"],
        },
        { merge: true }
      );

    console.log("Home Flyer uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};
export const editLiveEvent = async (values, tag) => {
  try {
    await db
      .collection("items")
      .doc(values.id)
      .set(
        {
          name: values.name,
          imageURL: values.imageURL,
          cdate: new Date(),
          tags: ["liveEvent"],
        },
        { merge: true }
      );

    console.log("Home Flyer uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};
export const uploadLiveEvent = async (values, tag) => {
  try {
    await db.collection("items").add({
      name: values.name,
      imageURL: values.imageURL,
      cdate: new Date(),
      tags: ["liveEvent"],
    });

    console.log("Live Event uploaded successfully!");
  } catch (error) {
    console.error("Error uploading live event:", error);
  }
};

export const deleteFirebaseItem = async (itemId) => {
  try {
    await db.collection("courses").doc(itemId).delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteFirebaseNotification = async (itemId) => {
  try {
    await db.collection("notifications").doc(itemId).delete();
  } catch (error) {
    console.log(error);
  }
};
export const setCourseActivation = async (itemId, state) => {
  console.log(itemId, state);
  try {
    await db.collection("courses").doc(itemId).set(
      {
        isActive: state,
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
  }
};
export const deleteFirebaseUser = async (itemId) => {
  try {
    await db.collection("courses").doc(itemId).delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteItem = async (itemId) => {
  try {
    await db
      .collection("businesses")
      .doc("root")
      .collection("items")
      .doc(itemId)
      .delete();
  } catch (error) {
    console.log(error);
  }
};
export const deleteVideo = async (courseId, itemId) => {
  try {
    await db
      .collection("courses")
      .doc(courseId)
      .collection("videos")
      .doc(itemId)
      .delete();
  } catch (error) {
    console.log(error);
  }
};
export const callDeleteAlert = (
  elementsToDelete,
  deleteItem,
  handleRefresh,
  successMsg
  //deleteElement
) => {
  Swal.fire({
    title: "Alerta",
    text: "¿Esta seguro que desea eliminar?",
    icon: "warning",
    confirmButtonText: "Aceptar",
    showDenyButton: true,
    denyButtonText: "Volver",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteItem(elementsToDelete);
      //deleteElement()
      Swal.fire(successMsg, "", "success");
      if (handleRefresh) {
        handleRefresh();
      }
    }
  });
};
export const callDeleteAlertCourse = (
  elementsToDelete,
  deleteFirebaseItem,
  handleRefresh,
  successMsg
  //deleteElement
) => {
  Swal.fire({
    title: "Alerta",
    text: "¿Esta seguro que desea eliminar?",
    icon: "warning",
    confirmButtonText: "Aceptar",
    showDenyButton: true,
    denyButtonText: "Volver",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteFirebaseItem(elementsToDelete);
      console.log(elementsToDelete);
      //deleteElement()
      Swal.fire(successMsg, "", "success");
      if (handleRefresh) {
        handleRefresh();
      }
    }
  });
};

// SIGNALS

export const changeCategory = (category) => {
  return (dispatch) => {
    dispatch(changeCat(category));
  };
};

export const changeSearch = (searchValue) => {
  return (dispatch) => {
    dispatch(searchChange(searchValue));
  };
};

export const getItems = (items) => ({
  type: SET_ITEMS,
  payload: {
    items,
  },
});

export const setCourses = (courses) => ({
  type: SET_COURSES,
  payload: {
    courses,
  },
});

export const changeCat = (category) => ({
  type: types.changeCategory,
  payload: {
    category,
  },
});

export const searchChange = (searchValue) => ({
  type: types.searchValue,
  payload: {
    searchValue,
  },
});

export const isLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  payload: {
    isLoading,
  },
});
