import React from "react";
import { styles } from "../../../assets/styles/styles";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { academyData } from "../../utils/homeData";
import { Button } from "@mui/base";
import Colors from "../../utils/Colors";

export const AcademyPromo = ({ theme }) => {
  return (
    <Box sx={styles.bePartBackground}>
      <Container>
        <Grid container justifyContent="center" spacing={2} mt={2}>
          <Grid
            item
            container
            md={12}
            sx={{ minHeight: "200px", mb: 4 }}
            justifyContent={"center"}
            alignItems={"center"}
            direction={"column"}
          >
            <Typography
              variant="h3"
              sx={{ fontSize: 32, color: "black", fontWeight: "600" }}
            >
              Nuestras academias
            </Typography>
            <Typography
              variant="h3"
              sx={{ fontSize: 32, color: "black", fontWeight: "600" }}
            >
              de Trading
            </Typography>
          </Grid>
          {academyData.map((item, index) => (
            <Grid item md={3.5} sm={6} key={item.id} my={{ xs: 8 }}>
              <Box
                sx={{
                  maxWidth: "100%",
                  height: 275,
                  position: "relative",
                  background: index !== 1 ? "#313b4b" : Colors.vibrantBlue,
                  backgroundSize: "cover",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -65%)",
                    width: "400px",
                    height: "auto",
                  }}
                >
                  <img
                    src={item.img}
                    alt="Absolute Image"
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: Colors.greyBlue,
                  minHeight: 110,
                  maxHeight: 110,
                  justifyContent: "center",
                  alignItems: "center",
                  pt: 1.5,
                  pb: 1,
                  pl: 1,
                  pr: 1,
                  [theme.breakpoints.down("md")]: {
                    // my: 6,
                  },
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ flex: 1, fontSize: 16, textAlign: "center" }}
                >
                  {item.text}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flex: 1, fontSize: 16, textAlign: "center" }}
                >
                  {item.subtitle}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
