import { types } from "../types/types";

const initialState = {
  user: null,
  loggedIn: false,
  isAlmighty: false,
  isAdmin: false,
  isSuperAdmin: false,
  isModerator: false,
  isLoadingAuth: false,
  idToken: "",
  errorMsg: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...state,
        user: action.payload.user,
        loggedIn: action.payload.loggedIn,
        isAlmighty: action.payload.isAlmighty || false,
        isAdmin: action.payload.isAdmin || false,
        isSuperAdmin: action.payload.isSuperAdmin || false,
        isModerator: action.payload.isModerator || false,
        idToken: action.payload.idToken,
      };
    case types.isLoadingAuth:
      return {
        ...state,
        isLoadingAuth: action.payload.isLoadingAuth,
      };
    case types.errorMsg:
      return {
        ...state,
        errorCode: action.payload.errorCode,
        errorMsg: action.payload.errorMsg,
      };
    case types.logout:
      return initialState;

    default:
      return state;
  }
};
