import React from "react";
import { styles } from "../../../assets/styles/styles";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import { academyData, services, teamData } from "../../utils/homeData";

import Colors from "../../utils/Colors";
import team from "../../../assets/img/sección-6.png";
import { MdAdd } from "react-icons/md";

export const Team = ({ theme }) => {
  const cardSize = 325;
  return (
    <>
      <Box sx={styles.blueBg}>
        <Container>
          <Grid container justifyContent="center" spacing={2} columnGap={6}>
            <Grid
              item
              container
              md={5}
              sx={{ minHeight: "200px", mb: 4 }}
              justifyContent={"center"}
              direction={"column"}
              order={{ xs: 2, sm: 1 }}
            >
              <Typography variant="h4">Nuestro</Typography>
              <Typography variant="h3" mb={4}>
                Equipo
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "600" }} mb={4}>
                Vanguard es una comunidad dedicada a la educación y el éxito en
                los mercados financieros, se destaca por su enfoque en la
                transparencia y el crecimiento de todos sus miembros. Posee un
                ecosistema digital que permite a sus socios aprender y
                capitalizarse, a través de la combinación de educación,
                asesoramiento de expertos y el uso de sistemas automatizados de
                alta tecnología. Su revolucionario portafolio de oportunidades
                contemplan una verdadera vanguard ia para la comunidad, en una
                sola plataforma
              </Typography>

              <Typography variant="body1" sx={{ fontWeight: "600" }}>
                Su revolucionario portafolio de oportunidades contemplan una
                verdadera vanguard ia para la comunidad, en una sola plataforma
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              order={{ xs: 1, sm: 2 }}
              container
              justifyContent={"center"}
            >
              <Box
                component={"img"}
                src={team}
                sx={{
                  height: "550px",
                  marginLeft: "auto",
                  [theme.breakpoints.down("md")]: {
                    width: "90%",
                    height: "auto",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={4}>
        <Container>
          <Grid container justifyContent="center">
            <Grid
              item
              container
              md={6}
              sx={{ minHeight: "200px", mb: 4 }}
              justifyContent={"center"}
              direction={"column"}
            >
              <Typography variant="h4">Servicios automáticos</Typography>

              <Typography variant="body1" sx={{ fontWeight: "600" }} mb={4}>
                Descubre nuestra amplia gama de servicios financieros
                completamente automáticos, deja que los expertos se encarguen
                por ti.
              </Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              rowGap={2}
              columnGap={10}
              justifyContent={"center"}
              mb={8}
            >
              {services.map((item, index) => (
                <Grid
                  item
                  md={3.5}
                  sm={8}
                  xs={10}
                  key={item.id}
                  my={{ xs: 4 }}
                  sx={{ width: "100%" }}
                >
                  <Box
                    sx={{
                      maxWidth: "100%",
                      height: 275,
                      position: "relative",
                      background: "white",
                      backgroundSize: "cover",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -65%)",
                        width: "400px",
                        height: "auto",
                      }}
                    >
                      <img
                        src={item.img}
                        alt="Absolute Image"
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "auto",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: Colors.yellowAcademy,
                      minHeight: 75,
                      maxHeight: 110,
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",

                      // pt: 1.5,
                      // pb: 1,
                      // pl: 1,
                      // pr: 1,
                      // [theme.breakpoints.down("md")]: {
                      //   my: 6,
                      // },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        flex: 1,
                        fontSize: 18,
                        textAlign: "center",
                        width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </Grid>
              ))}
              {/* {teamData.map((item) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    key={item.id}
                    container
                    justifyContent={"center"}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#012B4F",
                        width: cardSize,
                        height: cardSize,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        borderRadius: "8px",
                        position: "relative",
                      }}
                    >
                      {item.img ? (
                        <Box mb={4}>
                          <img
                            src={item.img}
                            style={{ width: "275px", height: "auto" }}
                          />
                          <Typography variant="subtitle1" sx={{ px: 1 }}>
                            {item.text}
                          </Typography>
                          <Typography variant="subtitle1" sx={{ px: 1 }}>
                            {item.text2}
                          </Typography>
                        </Box>
                      ) : (
                        <Box mb={2}>
                          <Typography variant="subtitle1" sx={{ px: 1 }}>
                            {item.text}
                          </Typography>
                          <Typography variant="subtitle1" sx={{ px: 1 }}>
                            {item.text2}
                          </Typography>
                        </Box>
                      )}

                      <Box
                        mt={1}
                        pb={2}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          position: "absolute",
                          bottom: 0,
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <IconButton
                          sx={{
                            backgroundColor: Colors.vibrantBlue,
                            ml: 2,
                            width: 32,
                            height: 32,
                          }}
                        >
                          <MdAdd />
                        </IconButton>
                        <Typography
                          variant="h3"
                          sx={{
                            px: 2,
                            fontSize: 14,
                            mr: 2,
                            marginLeft: "auto",
                            textAlign: "right",
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })} */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
